import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { observer } from 'mobx-react';
import BackwardsIcon from '../../../../../assets/back.svg';
import WidgetOnOffHandler from './WidgetOnOffHandler';
import { useTranslation } from 'react-i18next';

const Title = styled.div`
  font-family: 'Pretendard';
  font-style: normal;
  font-size: 32px;
  font-weight: 700;

  color: #fafafa;
  cursor: default;
`;

const glowing = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(126, 64, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 25px rgba(100, 54, 180, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(126, 64, 255, 0.4);
  }
`;

const ButtonStyle = styled.div<{ $isActivated?: boolean; $isGlow?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 3%;
  height: 32px;

  box-sizing: border-box;

  background: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? 'rgba(126, 64, 255, 0.25)'
      : 'rgba(126, 64, 255, 0.5)'};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  text-align: center;

  color: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? '#727272'
      : '#fafafa'};
  cursor: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? 'not-allowed'
      : 'pointer'};

  ${(props) =>
    props.$isActivated !== undefined &&
    props.$isActivated === true &&
    props.$isGlow === true
      ? css`
          animation: ${glowing} 1.5s infinite;
        `
      : ''}

  width: fit-content;
`;

const DashboardMenubarContainer = styled.div`
  padding: 40px 20px 20px 20px;
  gap: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSvg = styled.img<{ $isActivated?: boolean }>`
  filter: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? 'invert(0.5)'
      : 'invert(0)'};
`;

interface DashboardMenubarProps {
  vm: any;
}

const DashboardMenubar = observer(({ vm }: DashboardMenubarProps) => {
  const { t } = useTranslation();
  return (
    <DashboardMenubarContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '50%',
          gap: '20px',
        }}
      >
        <Title>Dashboards</Title>
        <ButtonStyle
          onClick={
            vm.isPickCompleted && !vm.loadingState.draftboard
              ? () => vm.setOverallStep(2)
              : () => {}
          }
          $isActivated={vm.isPickCompleted && !vm.loadingState.draftboard}
          $isGlow={vm.isPickCompleted && !vm.loadingState.draftboard}
        >
          {t('draft.completedraft')}
        </ButtonStyle>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '50%',
          gap: '10px',
        }}
      >
        <ButtonStyle
          $isActivated={
            vm.backActivated &&
            !Object.values(vm.loadingState)
              .map((v) => v)
              .includes(true)
          }
          onClick={() => {
            vm.backTurn();
          }}
        >
          <StyledSvg
            src={BackwardsIcon}
            $isActivated={
              vm.backActivated &&
              !Object.values(vm.loadingState)
                .map((v) => v)
                .includes(true)
            }
            alt="svg"
          />
        </ButtonStyle>
        <ButtonStyle
          $isActivated={true}
          onClick={() => {
            vm.setShowOnOffHandler();
          }}
        >
          {t('draft.widgetsedit')}
        </ButtonStyle>
        <WidgetOnOffHandler vm={vm} />
      </div>
    </DashboardMenubarContainer>
  );
});

export default DashboardMenubar;
