import React from 'react';
import styled from 'styled-components';
import { TitleWithGoBack } from '../Title';
import { Label } from '../../../../components/common/input/Input';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const SignUpCompleteBox = styled.div`
  background: white;
  width: 500px;
  border-radius: 8px 0px 0px 8px;
  height: 100vh;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`;

const Content = styled.div`
  padding: 2rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Message = styled.p`
  margin: 2rem 0;
  font-size: 1rem;
  color: #333;
  flex-grow: 1;
  display: flex;
  font-weight: 300;
  font-size: 32px;
  align-items: flex-start;

  justify-content: center;

  flex-direction: column;
`;

const LinkContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const Link = styled.a`
  display: block;
  font-size: 16px;
  color: #0057ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const SignUpComplete = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SignUpCompleteBox>
        <Content>
          <TitleWithGoBack title={t(`auth.signup`)} />
          <Message>
            {t(`auth.description`)}
            <br />
            <br />
            <div
              style={{
                fontSize: '14px',
              }}
            >
              {t(`auth.readspam`)}
            </div>
          </Message>
          <LinkContainer>
            <Label>
              {t(`auth.stillnot`)}
              <br />
              <Link>{t(`auth.getari`)}</Link>
            </Label>
          </LinkContainer>
        </Content>
      </SignUpCompleteBox>
    </Container>
  );
};

export default SignUpComplete;
