import React from 'react';
import { MenuArea } from '../../../components/mainMenu/MenuArea';
import Dashboard from './component/stage2/WidgetsGrid';
import DashboardMenubar from './component/stage2/DashboardMenuBar';
import WinrateWidget from './widgets/WinrateWidget';
import DraftboardWidget from './widgets/DraftboardWidget';
import SimulateWidget from './widgets/SimulateWidget';
import RecommendChampWidget from './widgets/RecommendChampWidget';
import ChampChooseWidget from './widgets/ChampChooseWidget';
import { observer } from 'mobx-react';
import GlobalBanWidget from './widgets/GlobalBanWidget';
import HelpWidget from './widgets/HelpWidget';
import { useTranslation } from 'react-i18next';

const DashboardView = observer(({ vm }: any) => {
  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        minHeight: '100vh',
        backgroundColor: '#060611',
      }}
    >
      <div
        style={{
          maxWidth: '1600px',
          margin: '0 auto',
        }}
      >
        <DashboardMenubar vm={vm} />
        <Dashboard>
          {vm.WidgetShowState.draftboard && (
            <DraftboardWidget vm={vm} gridColumn="span 2" />
          )}
          {vm.WidgetShowState.winrate && (
            <WinrateWidget vm={vm} gridColumn="span 2" />
          )}
          {vm.WidgetShowState.chooseChamps && (
            <ChampChooseWidget vm={vm} gridColumn="span 2" />
          )}
          {vm.WidgetShowState.recommendChamps && (
            <RecommendChampWidget vm={vm} gridColumn="span 1" />
          )}
          {vm.WidgetShowState.simulate && (
            <SimulateWidget vm={vm} gridColumn="span 4" />
          )}
          {vm.WidgetShowState.globalban && (
            <GlobalBanWidget vm={vm} gridColumn="span 2" />
          )}
          {vm.WidgetShowState.help && (
            <HelpWidget vm={vm} gridColumn="span 1" />
          )}
        </Dashboard>
      </div>
    </div>
  );
});

export default DashboardView;
