import React from 'react';
import styled from 'styled-components';
import WidgetBox from '../component/stage2/WidgetContainer';
import SearchChampion from '../../../../components/search/SearchChampion';
import { observer } from 'mobx-react';
import BannedChampion from './components/BannedChampions';
import { Champ } from '../type';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{
  $isActivated?: boolean;
}>`
  width: 20%;
  height: 100%;
  background-color: ${(props) =>
    props.$isActivated ? 'rgba(126, 64, 255, 0.25)' : 'rgba(126, 64, 255, 1)'};
  border-radius: 8px;
  padding: 10px;

  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.$isActivated ? '#727272' : '#fafafa')};

  gap: 20px;
  cursor: ${(props) => (props.$isActivated ? 'default' : 'pointer')};
`;

const SelectedImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #fafafa;
`;

const Text = styled.div`
  text-align: center;
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 700;

  white-space: pre-wrap;
`;

const CurrentBansContainer = styled.div`
  width: 40%;
  height: 230px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #282828;

  color: white;

  padding: 10px;
  box-sizing: border-box;
`;

const SubText = styled.div`
  color: black;

  width: fit-content;
  padding: 4px;

  border-radius: 8px;
  background: #fafafa;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
`;

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    height: 20%;
  }
`;

const GlobalBanWidget = observer(({ vm }: any) => {
  const { t } = useTranslation();

  return (
    <WidgetBox vm={vm} type="globalban">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '20px',
          height: '100%',
          flexDirection: 'row',
          gap: '3%',
        }}
      >
        <SearchChampion
          containerHeight="230px"
          onChampionClick={vm.setBanSelectedChamp}
          cannotPickChampions={
            Object.values(vm.draftboardState.champTemplateBasic).concat(
              vm.globalBanState?.globalBan
            ) as Champ[]
          }
          isPickCompleted={vm.isPickCompleted}
        />
        <CurrentBansContainer>
          <SubText>{t(`draft.bannedchamp`)}</SubText>
          <ListContainer>
            {vm.globalBanState?.globalBan?.map(
              (champ: Champ, index: number) => (
                <BannedChampion
                  key={index}
                  champ={champ}
                  onClick={() => vm.removeGlobalBan(index)}
                />
              )
            )}
          </ListContainer>
        </CurrentBansContainer>
        <Container
          $isActivated={
            vm.globalBanState?.selectedChamp?.korName !== undefined
              ? false
              : true
          }
          onClick={() => {
            vm.globalBanState?.selectedChamp?.korName && vm.addGlobalBan();
          }}
        >
          {vm.globalBanState?.selectedChamp?.image && (
            <SelectedImage src={vm.globalBanState?.selectedChamp?.image} />
          )}
          <Text>{`${
            vm.globalBanState?.selectedChamp?.korName
              ? vm.globalBanState?.selectedChamp?.korName +
                `\n${t('draft.bannow')}`
              : t('draft.goban')
          }`}</Text>
        </Container>
      </div>
    </WidgetBox>
  );
});

export default GlobalBanWidget;
