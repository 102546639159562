import React, { useRef, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import WidgetBox from '../component/stage2/WidgetContainer';
import BanResultSection from './components/BoardSection';
import PositionsIcon from '../../../../assets/positions.svg';
import Arrow from '../../../../assets/positionImplyArrow.svg';
import PosToggleSwitch from './components/toggleSwitch';
import { observer } from 'mobx-react';
import { Champ } from '../type';
import { LoadingSpinner } from '../component/stage2/loadingSpinner';
import LeftIcon from '../../../../assets/leftSelect.svg';
import RightIcon from '../../../../assets/rightSelect.svg';
import TeamPlayerSection from './components/Players';
import { useTranslation } from 'react-i18next';

const PlusInfoBoard = styled.div`
  @media (max-width: 1400px) {
    display: none;
  }

  width: 379px;
  height: 214px;

  background: #ffffff;
  border-radius: 8px;

  font-family: Pretendard;
  font-style: normal;
  font-size: 12px;
  font-weight: 300;

  white-space: pre-wrap;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;

  flex-direction: column;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
  align-items: flex-start;
  justify-content: center;
`;

const ChampionImage = styled.img`
  width: 40px;
  height: 40px;
`;

const PositionBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 229px;
  padding: 8px 0px;
  box-sizing: border-box;
`;

const PositionImplyBar = styled.div`
  width: 229px;
  position: relative;
`;

const PositionImplier = styled.div<{
  top: number;
  jungle: number;
  mid: number;
  adc: number;
  support: number;
}>`
  width: 229px;
  height: 32px;

  margin-top: 10px;

  background: linear-gradient(
    90deg,
    ${({ top }) => `rgba(126, 64, 255, ${(top * 2) / 100})`} 5%,
    ${({ jungle }) => `rgba(126, 64, 255, ${(jungle * 2) / 100})`} 28%,
    ${({ mid }) => `rgba(126, 64, 255, ${(mid * 2) / 100})`} 50%,
    ${({ adc }) => `rgba(126, 64, 255, ${(adc * 2) / 100})`} 73%,
    ${({ support }) => `rgba(126, 64, 255, ${(support * 2) / 100})`} 95%
  );
`;

const ChamjoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;

  bottom: 5px;

  z-index: 10;
`;

const Chamjo = styled.div<{ $isActivated: boolean }>`
  width: 6px;
  height: 6px;

  background-color: ${(props) =>
    props.$isActivated ? '#7e40ff' : '#7e40ff30'}; // 배경색 변경
`;

const ImplyLabel = ({ max, left }: { max: number; left: number }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'center',
        alignItems: 'center',

        position: 'absolute',
        left: `${left}%`,
      }}
    >
      <img src={Arrow} alt="arrow" width={8} />
      <div
        style={{
          fontFamily: 'Beaufort for LOL',
          fontStyle: 'italic',
          fontWeight: 900,
          fontSize: '16px',

          textAlign: 'center',
          color: '#242424',
        }}
      >
        {max.toFixed(1)}%
      </div>
    </div>
  );
};

const Context = styled.div`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;

  color: #242424;
  white-space: pre-wrap;

  text-align: left;
`;

const LeftButton = styled.img`
  cursor: pointer;
`;
const RightButton = styled.img`
  cursor: pointer;
`;

const Section = styled.div`
  flex: 0 0 340px; // Section width matches PlusInfoBoard width
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
`;

const StatusIcon = ({
  sectionNumber,
  setSectionNumber,
}: {
  sectionNumber: number;
  setSectionNumber: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <ChamjoContainer>
      <LeftButton
        src={LeftIcon}
        onClick={() => setSectionNumber(sectionNumber === 0 ? 1 : 0)}
        width={30}
      />
      {[sectionNumber === 0, sectionNumber === 1].map((isActivated, index) => (
        <Chamjo key={index} $isActivated={isActivated} />
      ))}
      <RightButton
        src={RightIcon}
        onClick={() => setSectionNumber((sectionNumber + 1) % 2)}
        width={30}
      />
    </ChamjoContainer>
  );
};

const PlusInfo = observer(({ vm }: { vm: any }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentSection, setCurrentSection] = useState(0);

  const handleWheel = useCallback(
    (e: React.WheelEvent) => {
      if (!scrollRef.current) return;

      e.preventDefault();

      // deltaMode가 0이면 트랙패드, 1이면 휠 마우스
      const isTrackpad = e.deltaMode === 0;
      const delta = isTrackpad ? e.deltaY * 0.5 : e.deltaY;

      if (delta > 20 && currentSection < 1) {
        setCurrentSection((prev) => prev + 1);
      } else if (delta < -20 && currentSection > 0) {
        setCurrentSection((prev) => prev - 1);
      }
    },
    [currentSection]
  );

  useEffect(() => {
    const currentRef = scrollRef.current;

    const handleMouseEnter = (e: Event) => {
      e.preventDefault();
    };

    const handleMouseLeave = () => {};

    if (currentRef) {
      currentRef.addEventListener(
        'wheel',
        handleWheel as unknown as EventListener,
        {
          passive: false,
        }
      );
      currentRef.addEventListener('mouseenter', handleMouseEnter);
      currentRef.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener(
          'wheel',
          handleWheel as unknown as EventListener
        );
        currentRef.removeEventListener('mouseenter', handleMouseEnter);
        currentRef.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [handleWheel]);

  return (
    <PlusInfoBoard ref={scrollRef}>
      <div
        style={{
          display: 'flex',
          transform: `translateX(-${currentSection * 340}px)`,
          transition: 'transform 0.5s ease',
          width: '100%',
          height: '100%',
        }}
      >
        <Section>
          <PositionInfo vm={vm} />
        </Section>
        <Section>
          <TeamAndPlayerInfo vm={vm} />
        </Section>
      </div>
      <StatusIcon
        sectionNumber={currentSection}
        setSectionNumber={setCurrentSection}
      />
    </PlusInfoBoard>
  );
});

const SubTitle = styled.div`
  width: 100%;

  font-family: Pretendard;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: row;
`;

const TeamAndPlayerInfo = ({ vm }: { vm: any }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: 600,
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <SubTitle>
          <div
            style={{
              color: '#2c31ab',
            }}
          >
            {vm.blue_roaster || ''}
          </div>
          <div
            style={{
              display: 'flex',
              color: '#000000',
            }}
          >
            vs
          </div>
          <div
            style={{
              color: '#b20808',
            }}
          >
            {vm.red_roaster || ''}
          </div>
        </SubTitle>
      </div>
      <TeamPlayerSection
        team="blue"
        players={vm.blueTeamPlayers}
        editable={false}
        vm={vm}
      />
      <TeamPlayerSection
        team="red"
        players={vm.redTeamPlayers}
        editable={false}
        vm={vm}
      />
    </div>
  );
};

const PositionInfo = observer(({ vm }: { vm: any }) => {
  const { t } = useTranslation();
  const data =
    vm.draftboardState?.positionInfo[vm.draftboardState?.chosenChampionPos];

  const max = Math.max(
    data?.top || 0,
    data?.jungle || 0,
    data?.mid || 0,
    data?.adc || 0,
    data?.support || 0
  );
  const maxPosition = Object.keys(data || {}).find(
    (key) => data && data[key as keyof typeof data] === max
  );

  const translateV = {
    top: -5,
    jungle: 20,
    mid: 43,
    adc: 66,
    support: 90,
  };

  const korPosition = {
    top: '탑',
    jungle: '정글',
    mid: '미드',
    adc: '원딜',
    support: '서폿',
  };

  if (vm.loadingState.draftboard === true) {
    return <LoadingSpinner width={30} />;
  } else if (data?.champ === null) {
    return <div>{t(`draft.checkpos`)}</div>;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {vm.isPickCompleted ? (
        <div
          style={{
            fontSize: '16px',
          }}
        >
          {'픽이 완료되었습니다. \n드래프트 완료하기 버튼을 눌러주세요.'}
        </div>
      ) : data?.top &&
        data?.jungle &&
        data?.mid &&
        data?.adc &&
        data?.support ? (
        <>
          <RowFlex>
            <ChampionImage src={data?.champ?.image} />
            <PositionBar>
              <img src={PositionsIcon} alt="positions" />
              <PositionImplier
                top={data?.top}
                jungle={data?.jungle}
                mid={data?.mid}
                adc={data?.adc}
                support={data?.support}
              />
              <PositionImplyBar>
                <ImplyLabel
                  max={max}
                  left={translateV[maxPosition as keyof typeof translateV]}
                />
              </PositionImplyBar>
            </PositionBar>
          </RowFlex>
          <Context>
            {`${data?.champ?.korName} 픽은 ${
              korPosition[maxPosition as keyof typeof translateV]
            }에 배정될 확률이 높습니다.`}
          </Context>
        </>
      ) : (
        <div>{t(`draft.checkpos`)}</div>
      )}
    </div>
  );
});

const DraftboardWidget = observer(({ vm }: any) => {
  const { t } = useTranslation();

  return (
    <WidgetBox vm={vm} type="draftboard">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
          padding: '20px',
          gap: '5%',
        }}
      >
        <BanResultSection width="40%" vm={vm} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            top: '-5%',

            gap: '10px',
          }}
        >
          <PosToggleSwitch vm={vm} />
          <PlusInfo vm={vm} />
        </div>
      </div>
    </WidgetBox>
  );
});

export default DraftboardWidget;
