// predictApis.ts
import apiClient from './apiClient';
import {
  MakePredictRequest,
  MakePredictResponse,
  NonSyncMakePredictRequest,
  NonSyncMakePredictResponse,
  NonSyncMakeMetaDataPredictRequest,
  NonSyncMakeMetaDataPredictResponse,
  PredictWithIdsRequest,
  PredictWithIdsResponse,
} from './types/predictionApiTypes';
import { PredictResponse } from '../pages/inference/recommend/type';

// Make Prediction
export const makePredict = async (
  predictData: MakePredictRequest
): Promise<MakePredictResponse> => {
  try {
    const response = await apiClient.post<MakePredictResponse>(
      '/predict/getPredict',
      predictData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Non-Sync Make Prediction
export const nonSyncMakePredict = async (
  predictData: NonSyncMakePredictRequest
): Promise<PredictResponse> => {
  try {
    const response = await apiClient.post<PredictResponse>(
      '/predict/getPredictNonSync',
      predictData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const makePredictionWithIds = async (
  predictData: PredictWithIdsRequest
) => {
  try {
    const response = await apiClient.post<PredictWithIdsResponse>(
      '/predict/getPredictWithIds',
      predictData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const predictWithMetaData = async (
  predictData: NonSyncMakeMetaDataPredictRequest
): Promise<NonSyncMakeMetaDataPredictResponse> => {
  try {
    const response = {
      data: {
        code: 200,
        data: {
          data: {
            blue_adc_player: 1,
            blue_jungle_player: 2,
            blue_mid_player: 3,
            blue_support_player: 4,
            blue_top_player: 5,
            red_adc_player: 6,
            red_jungle_player: 7,
            red_mid_player: 8,
            red_support_player: 9,
            red_top_player: 10,
            result: {
              accuracy: 0.8,
              is_blue_win: true,
            },
            status: 'success',
            user_id: 1,
          },
          metadata: {
            poble: {
              accuracy: 0.8,
              winner: 'blue',
            },
            cajung: {
              accuracy: 0.5,
              winner: 'blue',
            },
            invade: {
              accuracy: 0.6,
              winner: 'red',
            },
            dragon: {
              accuracy: 0.7,
              winner: 'blue',
            },
            baron: {
              accuracy: 0.9,
              winner: 'blue',
            },
            vision: {
              accuracy: 0.8,
              winner: 'blue',
            },
            cs10: {
              accuracy: 0.7,
              winner: 'red',
            },
          },
          result: {
            accuracy: 0.5,
            is_blue_win: true,
          },
        },
        message: 'success',
      },
    };
    return response.data;
  } catch (error) {
    throw error;
  }
};
