import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../../hooks/context/AuthContext';
import { ContinueButton } from '../../../../components/common/button/ContinueButton';
import { Label } from '../../../../components/common/input/Input';
import { Title } from '../Title';
import EmailInput from '../../../../components/common/input/EmailInput';
import Notification from '../../../../components/common/Notification';
import { useTranslation } from 'react-i18next';
import LangToggle from '../../../../components/common/langtoggle';

const LoginBox = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  padding: 60px 60px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  text-align: left;
`;

const SubTitle = styled.div`
  font-size: 24px;
  color: #666;
`;

const SubTitle2 = styled.div`
  margin-top: 150px;
  font-size: 20px;
  color: #666;
`;

const Link = styled.a`
  display: block;
  font-size: 20px;
  color: #0057ff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 30px;
`;

const IntroductionLink = styled(Link)`
  font-size: 24px;
`;

const AuthStep1 = () => {
  const {
    setStep,
    setLoginEmail,
    loginEmail,
    clickEmailSumbit,
    notiStatus,
    notiText,
    notiDisplay,
  } = useAuth();
  const [isEmailValid, setIsEmailValid] = useState(true);

  const { t } = useTranslation();

  const handleContinue = () => {
    if (isEmailValid) {
      clickEmailSumbit();
    } else {
      setIsEmailValid(false);
    }
  };

  const handleSignUp = () => {
    setStep(3);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginEmail(event.target.value);
    setIsEmailValid(validateEmail(event.target.value));
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Notification
        text={notiText}
        status={notiStatus}
        isDisplay={notiDisplay}
      />
      <LoginBox>
        <Title>{t(`auth.login`)}</Title>
        <MessageContainer>
          <SubTitle>{t(`auth.new_user?`)}</SubTitle>
          <IntroductionLink href="#">{t(`auth.getari`)}</IntroductionLink>
        </MessageContainer>
        <Label htmlFor="email">{t(`auth.email`)}</Label>
        <EmailInput onChange={handleEmailChange} value={loginEmail} />
        <ContinueButton onClick={handleContinue} title={t(`auth.next`)} />
        <SubTitle2>
          {t(`auth.is_member?`)}{' '}
          <Link onClick={handleSignUp}>{t(`auth.signup`)}</Link>
          <br />
          {t(`auth.forget_id`)}{' '}
          <Link href="https://forms.gle/DrHUMMZrRag48dbE9" target="_blank">
            {t(`auth.help`)}
          </Link>
        </SubTitle2>
      </LoginBox>
    </div>
  );
};

export default AuthStep1;
