// predictApis.ts
import apiClient from './apiClient';
import {
  GetRecommendRequest,
  GetRecommendResponse,
  GetPredictionRecommendRequest,
  GetPredictionRecommendResponse,
  GetRecommendWithIdsRequest,
  GetRecommendWithIdsResponse,
} from './types/recommendApiTypes';
import { RecommendResponse } from '../pages/inference/recommend/type';
import useChampions from '../hooks/useChampions';

// Make Prediction
export const makeRecommend = async (
  recommendData: GetRecommendRequest
): Promise<RecommendResponse> => {
  try {
    const response = await apiClient.post<RecommendResponse>(
      '/recommend/getRecommendNonSync',
      recommendData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const makePredictionRecommend = async (
  predictData: GetPredictionRecommendRequest
): Promise<GetPredictionRecommendResponse> => {
  try {
    const response = await apiClient.post<GetPredictionRecommendResponse>(
      '/recommend/getPrediction',
      predictData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const makeRecommendWithIds = async (
  recommendData: GetRecommendWithIdsRequest
): Promise<GetRecommendWithIdsResponse> => {
  try {
    const response = await apiClient.post<GetRecommendWithIdsResponse>(
      '/recommend/getRecommendWithIds',
      recommendData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
