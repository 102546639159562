import { HistoryRecord } from '../../../hooks/context/HistoryContext';

export interface RecommendChamp {
  id: number;
  grade: number;
  percentage: number;
  tag: string | null;
  image: string | null;
  name: string | null;
  korName: string | null;
  furtherArray: number | null;
}

export interface SelectChamp {
  id: number;
  name: string | null;
  korName: string | null;
  image: string | null;
}

export interface HighlightedPosition {
  team: 'blue' | 'red';
  position: 'top' | 'jungle' | 'mid' | 'adc' | 'support';
  championId: number | null;
  isPick: boolean;
  image: string | null;
}

export interface NowTurn {
  turn: number;
  nowShouldPick: HighlightedPosition;
  instruction: string;
}

export interface HelpWidgetState {
  selectedWidget: WidgetTypes;
}

export type Champ = {
  index: number;
  name: string;
  korName: string;
  image: string;
  isSimulated?: boolean;
};

export type PositionInfo = {
  [key: number]: {
    champ: Champ | null;
    top: number;
    jungle: number;
    mid: number;
    adc: number;
    support: number;
  };
};

export type Template = {
  redbantop: Champ | null;
  redbanjungle: Champ | null;
  redbanmid: Champ | null;
  redbanadc: Champ | null;
  redbansupport: Champ | null;
  redpicktop: Champ | null;
  redpickjungle: Champ | null;
  redpickmid: Champ | null;
  redpickadc: Champ | null;
  redpicksupport: Champ | null;
  bluebantop: Champ | null;
  bluebanjungle: Champ | null;
  bluebanmid: Champ | null;
  bluebanadc: Champ | null;
  bluebansupport: Champ | null;
  bluepicktop: Champ | null;
  bluepickjungle: Champ | null;
  bluepickmid: Champ | null;
  bluepickadc: Champ | null;
  bluepicksupport: Champ | null;
};

export interface DraftboardState {
  chosenChampionPos: number | null;
  positionActivated: boolean;
  positionInfo: PositionInfo | null;
  champTemplateBasic: Template;
  champTemplateWithPosition: Template;
}

export interface TurnBlob {
  turn: number;
  winrate: number;
  pickrate: number;
  pickorder: number;
  champ: Champ;
  recommendations: Champ[];
  positionPredict: number[];
}

export interface WinrateState {
  data: {
    [key: number]: TurnBlob;
  };
}

export interface ChooseChampsState {
  selectedPosition: 'top' | 'jungle' | 'mid' | 'adc' | 'support' | null;
  selectedChamp: Champ | null;
  searchKeyword: string;
}

export interface RecommendedChamp extends Champ {
  winrate: number;
  pickrate: number;
  positionPredict: number[];
  order: number;
  isMyosu: boolean;
  isBest: boolean;
}

export interface RecommendChampsState {
  selectedChamp: Champ | null;
  recommendChamps: RecommendedChamp[];
  recommendLogicK: number;
  recommendLogicP: number;
}

export interface GlobalBanState {
  globalBan: Champ[];
  selectedChamp: Champ | null;
}

export interface SimulateState {}

export type WidgetTypes =
  | 'draftboard'
  | 'winrate'
  | 'chooseChamps'
  | 'recommendChamps'
  | 'simulate'
  | 'globalban'
  | 'help';

export interface Widget {
  name:
    | '드래프트 보드'
    | '밴픽 스코어'
    | '챔피언 선택'
    | '챔피언 추천'
    | '시뮬레이션';
  type: WidgetTypes;
  pinned: boolean;
  show: boolean;
  state:
    | DraftboardState
    | WinrateState
    | ChooseChampsState
    | RecommendChampsState
    | SimulateState;
}

export interface ParentSitu {
  turn: number;
  widgets: Widget[];
  selectedChamp: Champ | null;
}

export interface Metadata {
  position: '탑' | '정글' | '미드' | '원딜' | '서포터';
  exp: number;
  cs: number;
  dpm: number;
  gold: number;
  kda: number;
  vision: number;
}

export interface TeamMetadata {
  herald: number[];
  dragon: number[];
  baron: number[];
  soul: number[];
  elder: number[];
}

export interface PickPos {
  top: number;
  jug: number;
  mid: number;
  adc: number;
  sub: number;
}

export interface ResponsePositions {
  blue_pos: {
    pick1: PickPos;
    pick2: PickPos;
    pick3: PickPos;
    pick4: PickPos;
    pick5: PickPos;
  };
  red_pos: {
    pick1: PickPos;
    pick2: PickPos;
    pick3: PickPos;
    pick4: PickPos;
    pick5: PickPos;
  };
}

export interface PredictResponse {
  code: number;
  data: {
    result: {
      metadata: {
        adc: PositionMetrics;
        jungle: PositionMetrics;
        mid: PositionMetrics;
        support: PositionMetrics;
        top: PositionMetrics;
        team: TeamMetrics;
      };
      position: ResponsePositions;
      predrate: {
        pick_order: number;
        pick_rate: number;
      };
      winrate: {
        winrate: number;
      };
      patch_id: string;
      history_id: number;
    };
    data: MatchData;
  };
  message: string;
}

interface PositionMetrics {
  champExperience: Record<string, number>;
  dpm: Record<string, number>;
  gold: Record<string, number>;
  kda: Record<string, number>;
  minions: Record<string, number>;
  visions: Record<string, number>;
}

interface TeamMetrics {
  baron: Record<string, number>;
  dragon: Record<string, number>;
  elder: Record<string, number>;
  herald: Record<string, number>;
  soul: Record<string, number>;
}

interface PositionPicks {
  pick1: Record<string, number>;
  pick2: Record<string, number>;
  pick3: Record<string, number>;
  pick4: Record<string, number>;
  pick5: Record<string, number>;
}

interface MatchData {
  user_id: number;
  blue_roaster_id: number | null;
  red_roaster_id: number | null;
  type: string;
  status: string;
  blue_top: number;
  blue_jungle: number;
  blue_middle: number;
  blue_bottom: number;
  blue_support: number;
  red_top: number;
  red_jungle: number;
  red_middle: number;
  red_bottom: number;
  red_support: number;
}

export interface RecommendResponse {
  code: number;
  data: {
    result: {
      recommend: {
        recommended: RecommendedChamps[];
        order: { [key: string]: number };
      };
      patch_id: string;
    };
    data: MatchData;
  };
  message: string;
}

interface RecommendedChamps {
  champion_id: number;
  pred_rate: number;
  winpred: number;
}

export interface SimulatedTurnBlob extends TurnBlob {
  isSimulated: boolean;
}

export interface SimulateWinrateState {
  data: {
    [key: number]: SimulatedTurnBlob;
  };
}

export interface NewHistoryRecord extends HistoryRecord {
  graphData: any;
  metadata: Metadata[] | null;
  teamMetadata: TeamMetadata | null;
}

export class Stack<T> {
  private items: T[] = [];

  // 스택에 요소를 추가
  push(item: T): void {
    this.items.push(item);
  }

  // 스택에서 요소를 제거하고 반환
  pop(): T | undefined {
    return this.items.pop();
  }

  // 스택의 맨 위 요소를 반환 (제거하지 않음)
  peek(): T | undefined {
    return this.items[this.items.length - 1];
  }

  // 스택이 비어 있는지 확인
  isEmpty(): boolean {
    return this.items.length === 0;
  }

  // 스택의 크기 반환
  size(): number {
    return this.items.length;
  }

  // 스택 비우기
  clear(): void {
    this.items = [];
  }
}

export class Queue<T> {
  private items: T[] = [];

  // 큐에 요소를 추가
  enqueue(item: T): void {
    this.items.push(item);
  }

  // 큐에서 요소를 제거하고 반환
  dequeue(): T | undefined {
    return this.items.shift();
  }

  // 큐의 맨 앞 요소를 반환 (제거하지 않음)
  front(): T | undefined {
    return this.items[0];
  }

  // 큐가 비어 있는지 확인
  isEmpty(): boolean {
    return this.items.length === 0;
  }

  // 큐의 크기 반환
  size(): number {
    return this.items.length;
  }

  // 큐 비우기
  clear(): void {
    this.items = [];
  }
}
