import styled from 'styled-components';
import Logo from '../../assets/logo512.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navbar = styled.div`
  display: flex;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 2vw;
  background: white;
  border-bottom: 1px solid #f0f0f0;

  @media (max-width: 1200px) {
    height: 50px;
  }
`;

const NavbarItem = styled.a`
  font-size: 20px;
  color: #333;
  text-decoration: none;

  &:hover {
    color: #0057ff;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;
const NavbarTitle = styled.div`
  font-size: 24px;
  color: #333;
  padding: 0px 4vw;
  display: flex;
  align-self: center;
  justify-content: center;
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #000000 50.5%,
    rgba(0, 0, 0, 0) 100%
  );
  border-image-slice: 1;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

const NavbarTitle2 = styled.h1`
  font-size: 28px;
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 1200px) {
    font-size: 20px;
  }
`;

export const NavbarMenu = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Navbar>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          height: '100%',
          gap: '20px',
        }}
      >
        <img
          src={Logo}
          alt="logo"
          height={'50%'}
          onClick={() => {
            navigate('/home');
          }}
        />
        <NavbarTitle2
          onClick={() => {
            navigate('/home');
          }}
        >
          GLPT
        </NavbarTitle2>
      </div>
      <NavbarTitle>{title}</NavbarTitle>
      <NavbarItem href="https://forms.gle/DrHUMMZrRag48dbE9" target="_blank">
        {t(`auth.help`)}
      </NavbarItem>
    </Navbar>
  );
};
