import { observer } from 'mobx-react';
import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Define fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Toggle Container
const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: fit-content;
`;

// Toggle Switch
const Switch = styled.div<{ isActive: boolean }>`
  width: 40px;
  height: 28px;
  box-sizing: border-box;

  background: transparent;
  border-radius: 19px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  border: ${({ isActive }) =>
    isActive ? '4px solid #7e40ff' : '4px solid #fafafa'};
  position: relative;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Circle = styled.div<{ isActive: boolean }>`
  width: 12px;
  height: 12px;
  background: #7e40ff;
  border-radius: 50%;
  transition: transform 0.3s ease;
  transform: ${({ isActive }) =>
    isActive ? 'translateX(14px)' : 'translateX(0)'};
`;

const ToggleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #fafafa;
`;

const ToggleTextGray = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #727272;
`;

const CircleGray = styled.div`
  width: 12px;
  height: 12px;
  background: #7e40ff30;
  border-radius: 50%;
  transition: transform 0.3s ease;
`;

const SwitchGray = styled.div`
  width: 40px;
  height: 28px;
  box-sizing: border-box;

  background: transparent;
  border-radius: 19px;
  display: flex;
  align-items: center;
  padding: 3px;

  border: 4px solid #fafafa30;
  position: relative;
`;

const SimulatePosToggleSwitch = observer(({ vm }: any) => {
  const handleToggle = () => {
    vm.setSimPosToggle();
  };
  const { t } = useTranslation();

  if (!vm.isSimulatePickCompleted) {
    return (
      <Container>
        <ToggleContainer>
          <ToggleTextGray>{t('draft.getpos')}</ToggleTextGray>
          <SwitchGray>
            <CircleGray />
          </SwitchGray>
        </ToggleContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ToggleContainer>
        <ToggleText>{t('draft.getpos')}</ToggleText>
        <Switch isActive={vm.simPosToggle} onClick={handleToggle}>
          <Circle isActive={vm.simPosToggle} />
        </Switch>
      </ToggleContainer>
    </Container>
  );
});

const SwitchNotChanging = styled.div`
  width: 40px;
  height: 28px;
  box-sizing: border-box;

  background: transparent;
  border-radius: 19px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  border: 4px solid #fafafa;
  position: relative;
`;

const SimulateSearchSwitch = observer(({ vm }: any) => {
  const handleToggle = () => {
    vm.setSearchMode();
  };
  const { t } = useTranslation();

  return (
    <Container>
      <ToggleContainer>
        <ToggleText>{t('draft.champplayer')}</ToggleText>
        <SwitchNotChanging onClick={handleToggle}>
          <Circle isActive={vm.searchMode} />
        </SwitchNotChanging>
      </ToggleContainer>
    </Container>
  );
});

export { SimulatePosToggleSwitch, SimulateSearchSwitch };
