import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

// Define fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Toggle Container
const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: fit-content;
`;

// Toggle Switch
const Switch = styled.div<{ $isActive: boolean; $isNotUsable?: boolean }>`
  width: 40px;
  height: 28px;
  box-sizing: border-box;

  background: transparent;
  border-radius: 19px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: ${({ $isNotUsable }) => ($isNotUsable ? 'default' : 'pointer')};
  border: ${(props) =>
    props.$isNotUsable
      ? '4px solid #fafafa30'
      : props.$isActive
      ? '4px solid #7e40ff'
      : '4px solid #fafafa'};
  position: relative;

  transition: border 0.3s ease;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Circle = styled.div<{ $isActive: boolean; $isNotUsable?: boolean }>`
  width: 12px;
  height: 12px;
  background: ${(props) => (props.$isNotUsable ? '#7e40ff30' : '#7e40ff')};
  border-radius: 50%;
  transition: transform 0.3s ease, background 0.3s ease;
  transform: ${({ $isActive }) =>
    $isActive ? 'translateX(14px)' : 'translateX(0)'};
`;

const ToggleText = styled.div<{ $isNotUsable?: boolean }>`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.$isNotUsable ? '#fafafa30' : '#fafafa')};
  transition: color 0.3s ease;
`;

const PosToggleSwitch = observer(({ vm }: any) => {
  const handleToggle = () => {
    vm.changeActivatePositionView();
  };
  const { t } = useTranslation();

  return (
    <Container>
      <ToggleContainer>
        <ToggleText $isNotUsable={vm.loadingState.draftboard}>
          {t('draft.getpos')}
        </ToggleText>
        <Switch
          $isActive={vm.draftboardState.positionActivated}
          $isNotUsable={vm.loadingState.draftboard}
          onClick={!vm.loadingState.draftboard ? handleToggle : undefined}
        >
          <Circle
            $isActive={vm.draftboardState.positionActivated}
            $isNotUsable={vm.loadingState.draftboard}
          />
        </Switch>
      </ToggleContainer>
    </Container>
  );
});

export default PosToggleSwitch;
