import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/context/AuthContext';
import { useUserData } from '../../hooks/context/UserDataContext';
import { useEffect, useState } from 'react';
import Spinner from '../../components/common/loading';
import { useTranslation } from 'react-i18next';

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: url('/authbackground.png') no-repeat center center;
  background-size: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      95deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.8)
    );
    z-index: 1;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
  margin-bottom: 40px;
`;

const HeaderTitle = styled.h1`
  font-size: 48px;
  font-weight: 300;
  strong {
    font-weight: bold;
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-right: 20px;

  cursor: pointer;
`;

const ProfileName = styled.div`
  font-size: 24px;
  font-weight: 300;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
`;

const Card = styled.div`
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  &:hover {
    filter: brightness(0.95);
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 2rem;
  flex: 1;
  overflow: hidden;
`;

const CardTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 20px;
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  width: 70%;
  aspect-ratio: 3.3;
`;
const CardDescription = styled.p`
  display: block;
  margin: 10px 0px;
  font-size: 16px;
  text-overflow: ellipsis;
  font-weight: 300;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;
const CardContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse; /* 아이템을 아래에서 위로 쌓이게 함 */
  align-items: flex-start; /* 아이템을 왼쪽 정렬 */
  justify-content: flex-end; /* 아이템을 아래쪽 정렬 */
  text-align: left;
`;
const HomeArea = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { userData } = useUserData();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (userData.userName) {
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    if (!user) {
      navigate('/auth');
    }
  }, [user, navigate]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <BackgroundContainer>
      <Container>
        <Header>
          <HeaderTitle>
            {t(`menu.welcome`)}
            <br /> <strong>{userData.userName}</strong> {t(`menu.sir`)}
          </HeaderTitle>
          <Profile>
            <ProfileImage
              src={userData.profileImage || '/pProfile.png'}
              alt="Profile"
              onClick={() => handleNavigation('/userdata/private')}
            />
            <ProfileName>{userData.userName}</ProfileName>
          </Profile>
        </Header>
        <CardsContainer>
          <Card onClick={() => handleNavigation('/dashboard')}>
            <CardContentContainer>
              <CardTitle>Dashboard</CardTitle>
              <CardDescription>
                {t(`menu.dashboarddescription`)}
              </CardDescription>
            </CardContentContainer>
            <CardImage src="/ari-analyzer.png" alt="dashboard" />
          </Card>
          <Card onClick={() => handleNavigation('/userdata/history')}>
            <CardContentContainer>
              <CardTitle>History</CardTitle>
              <CardDescription>{t(`menu.historydescription`)}</CardDescription>
            </CardContentContainer>
            <CardImage src="/ari-history.png" alt="ARI History" />
          </Card>
        </CardsContainer>
      </Container>
    </BackgroundContainer>
  );
};
export default HomeArea;
