import styled from 'styled-components';
import { RoasterData } from '../../../../userdata/roaster/type';
import TooltipButton from './TooltipButton';
import { observer } from 'mobx-react';

// MiddleMenu 컨테이너 정의
const MiddleMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  min-width: 100px;
  max-width: 200px;
  align-items: center;
  background: #f6f6f6;
  @media (max-width: 1048px) and (min-width: 768px) {
    max-width: 100px;
  }
`;

// 버튼 그룹 정의
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
`;

// 선택 박스 정의

const MiddleMenu = observer(({ vm }: any) => {
  const handleImportRoaster = (team: string, roasterData: RoasterData) => {
    const formattedPlayers = roasterData.playerList.map((player) => ({
      id: player.id,
      name: player.name,
      image: player.image || '',
      line: player.position || '',
      champion: '',
      championImage: '',
    }));

    if (team === 'blue') {
      vm.setBlueTeamPlayers(formattedPlayers);
    } else {
      vm.setRedTeamPlayers(formattedPlayers);
    }
  };

  return (
    <MiddleMenuContainer>
      <ButtonGroup>
        <TooltipButton
          team="blue"
          onClick={(roasterData) => handleImportRoaster('blue', roasterData)}
          setRoasterName={vm.setBlueRoasters}
        />
        <TooltipButton
          team="red"
          onClick={(roasterData) => handleImportRoaster('red', roasterData)}
          setRoasterName={vm.setRedRoasters}
        />
      </ButtonGroup>
    </MiddleMenuContainer>
  );
});

export default MiddleMenu;
