import { MenuArea } from '../../components/mainMenu/MenuArea';
import HomeArea from './HomeArea';
import { UserDataProvider } from '../../hooks/context/UserDataContext';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <MenuArea title={t(`menu.home`)} selectedItem="홈" initialOpen={true}>
      <UserDataProvider>
        <HomeArea />
      </UserDataProvider>
    </MenuArea>
  );
};

export default Home;
