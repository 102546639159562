import { makeAutoObservable, observable, toJS } from 'mobx';
import { NewHistoryRecord } from './type';
import * as VisTypes from './type';
import { RoasterPlayer } from '../../userdata/roaster/type';
import {
  makeRecommend,
  makeRecommendWithIds,
} from '../../../apis/recommendApis';
import {
  nonSyncMakePredict,
  makePredictionWithIds,
} from '../../../apis/predictionApis';
import { Stack, Queue } from './type';
import { fetchChampionsData } from '../../../hooks/useChampions';
import { putHistory } from '../../../apis/historyApis';
import { getPatch } from '../../../apis/riotApis';
import loadash from 'lodash';

class RecommendVM {
  overallStep: number = 0;
  metadataInfo = null;

  winrateDataContainer = {};
  turnWeight = [
    0.8, 0.8, 0.8, 0.8, 0.8, 0.75, 0.75, 0.38, 0.36, 0.36, 0.38, 0.38, 0.36,
    0.8, 0.8, 0.8, 0.8, 0.65, 0.69, 0.69, 0.65,
  ];

  // 상태를 정의
  draftboardState: VisTypes.DraftboardState = {
    chosenChampionPos: 0,
    positionActivated: false,
    positionInfo: {
      0: { champ: null, top: 0, jungle: 0, mid: 0, adc: 0, support: 0 },
    },
    champTemplateBasic: {
      redbantop: null,
      redbanjungle: null,
      redbanmid: null,
      redbanadc: null,
      redbansupport: null,
      bluebantop: null,
      bluebanjungle: null,
      bluebanmid: null,
      bluebanadc: null,
      bluebansupport: null,
      bluepicktop: null,
      bluepickjungle: null,
      bluepickmid: null,
      bluepickadc: null,
      bluepicksupport: null,
      redpicktop: null,
      redpickjungle: null,
      redpickmid: null,
      redpickadc: null,
      redpicksupport: null,
    },
    champTemplateWithPosition: {
      redbantop: null,
      redbanjungle: null,
      redbanmid: null,
      redbanadc: null,
      redbansupport: null,
      bluebantop: null,
      bluebanjungle: null,
      bluebanmid: null,
      bluebanadc: null,
      bluebansupport: null,
      bluepicktop: null,
      bluepickjungle: null,
      bluepickmid: null,
      bluepickadc: null,
      bluepicksupport: null,
      redpicktop: null,
      redpickjungle: null,
      redpickmid: null,
      redpickadc: null,
      redpicksupport: null,
    },
  };

  winrateState: VisTypes.WinrateState = {
    data: {
      0: {
        turn: 0,
        winrate: -1,
        pickrate: 0,
        pickorder: 0,
        champ: {
          index: 0,
          name: '',
          image: '',
          korName: '',
        },
        recommendations: [],
        positionPredict: [],
      },
    },
  };

  chooseChampsState: VisTypes.ChooseChampsState = {
    selectedPosition: null,
    selectedChamp: null,
    searchKeyword: '',
  };

  recommendChampsState: VisTypes.RecommendChampsState = {
    selectedChamp: null,
    recommendChamps: [],
    recommendLogicK: 5,
    recommendLogicP: 53,
  };

  globalBanState: VisTypes.GlobalBanState = {
    globalBan: [],
    selectedChamp: null,
  };

  helpWidgetState: VisTypes.HelpWidgetState = {
    selectedWidget: 'help',
  };

  public setHelpState = (widget: VisTypes.WidgetTypes) => {
    this.helpWidgetState.selectedWidget = widget;
  };

  parentState: VisTypes.ParentSitu = {
    turn: 0,
    widgets: [
      {
        type: 'draftboard',
        name: '드래프트 보드',
        pinned: false,
        show: true,
        state: this.draftboardState,
      },
    ],
    selectedChamp: null,
  };

  blue_roaster: string | null = null;
  red_roaster: string | null = null;

  public recommendResetWeight: boolean = true;
  public setRecommendResetWeight = () => {
    this.recommendResetWeight = !this.recommendResetWeight;
  };

  public recommendSideIsChoosing: boolean = false;
  public setRecommendSideIsChoosing = () => {
    this.recommendSideIsChoosing = !this.recommendSideIsChoosing;
  };

  public setBanSelectedChamp = (champ: VisTypes.Champ | null) => {
    this.globalBanState.selectedChamp = champ;
  };

  public addGlobalBan = () => {
    if (this.globalBanState.selectedChamp) {
      this.globalBanState.globalBan.push(this.globalBanState.selectedChamp);
      this.globalBanState.selectedChamp = null;
    } else {
      return;
    }
  };

  public removeGlobalBan = (index: number) => {
    this.globalBanState.globalBan.splice(index, 1);
  };

  public patchLists: string[] | null = null;

  public getPatchVersion = async () => {
    await getPatch()
      .then((res) => {
        this.patchLists = res.data;
      })
      .catch((err) => {
        console.error(err);
        this.patchLists = [];
      });
  };

  public backActivated = false;

  public backToOverallStep = (turn: number) => {
    this.overallStep = turn;
  };

  private allTurnStateHistory: {
    [key: number]: {
      draftboard: VisTypes.DraftboardState | null;
      recommendChamp: VisTypes.RecommendChampsState | null;
    };
  } = {
    0: {
      draftboard: this.draftboardState,
      recommendChamp: this.recommendChampsState,
    },
  };

  public backTurn = () => {
    if (
      this.parentState.turn === 0 ||
      !this.backActivated
      // || Object.values(this.loadingState).includes(true)
    ) {
      return;
    }

    delete this.allTurnStateHistory[this.parentState.turn];

    const allStateBefore = this.allTurnStateHistory[this.parentState.turn - 1];

    this.draftboardState = allStateBefore.draftboard!;
    delete this.winrateState.data[this.parentState.turn];
    this.recommendChampsState = allStateBefore.recommendChamp!;

    if (this.parentState.turn === 20) {
      this.isPickCompleted = false;
    }

    this.parentState.turn -= 1;

    if (this.parentState.turn === 0) {
      this.backActivated = false;
    }
  };

  convertToCurrentChampion = (
    champTemplate: VisTypes.Template,
    isBan: boolean
  ) => {
    let currentChampion = [];
    if (isBan) {
      currentChampion = [
        champTemplate.bluebantop === null ? 0 : champTemplate.bluebantop.index,
        champTemplate.bluebanjungle === null
          ? 0
          : champTemplate.bluebanjungle.index,
        champTemplate.bluebanmid === null ? 0 : champTemplate.bluebanmid.index,
        champTemplate.bluebanadc === null ? 0 : champTemplate.bluebanadc.index,
        champTemplate.bluebansupport === null
          ? 0
          : champTemplate.bluebansupport.index,
        champTemplate.redbantop === null ? 0 : champTemplate.redbantop.index,
        champTemplate.redbanjungle === null
          ? 0
          : champTemplate.redbanjungle.index,
        champTemplate.redbanmid === null ? 0 : champTemplate.redbanmid.index,
        champTemplate.redbanadc === null ? 0 : champTemplate.redbanadc.index,
        champTemplate.redbansupport === null
          ? 0
          : champTemplate.redbansupport.index,
      ];
    } else {
      currentChampion = [
        champTemplate.bluepicktop === null
          ? 0
          : champTemplate.bluepicktop.index,
        champTemplate.bluepickjungle === null
          ? 0
          : champTemplate.bluepickjungle.index,
        champTemplate.bluepickmid === null
          ? 0
          : champTemplate.bluepickmid.index,
        champTemplate.bluepickadc === null
          ? 0
          : champTemplate.bluepickadc.index,
        champTemplate.bluepicksupport === null
          ? 0
          : champTemplate.bluepicksupport.index,
        champTemplate.redpicktop === null ? 0 : champTemplate.redpicktop.index,
        champTemplate.redpickjungle === null
          ? 0
          : champTemplate.redpickjungle.index,
        champTemplate.redpickmid === null ? 0 : champTemplate.redpickmid.index,
        champTemplate.redpickadc === null ? 0 : champTemplate.redpickadc.index,
        champTemplate.redpicksupport === null
          ? 0
          : champTemplate.redpicksupport.index,
      ];
    }
    return currentChampion;
  };

  setRequest = () => {
    const patch = this.patchVersion === 'latest' ? '0.0' : this.patchVersion;
    const playerList = this.blueTeamPlayers
      .concat(this.redTeamPlayers)
      .map((p) => p.id);
    const blue_roaster = null;
    const red_roaster = null;
    const current_champion = this.convertToCurrentChampion(
      this.draftboardState.champTemplateBasic,
      false
    );
    const current_ban_champion = this.convertToCurrentChampion(
      this.draftboardState.champTemplateBasic,
      true
    );

    const PReq = {
      patch: patch,
      playerList: playerList,
      blue_roaster: blue_roaster,
      red_roaster: red_roaster,
      current_champion: current_champion,
      current_ban_champion: current_ban_champion,
    };

    return PReq;
  };

  private setWinRateStack = new Stack();
  public isLoadingWinrate: boolean | null = null;

  private winrateLoadingQueue = new Queue();
  private draftLoadingQueue = new Queue();
  private recommendLoadingQueue = new Queue();

  public loadingState = {
    winrate: false,
    draftboard: false,
    recommend: false,
  };

  private history_id: number | null = null;

  setStage1Winrates = async () => {
    const req = this.setRequest();
    this.isActivateStep2 = false;

    if (this.setWinRateStack.size() >= 3) {
      alert('요청이 너무 많습니다. 잠시 후 다시 시도해주세요.');
      return;
    }

    this.setWinRateStack.push(req);

    if (this.setWinRateStack.isEmpty()) {
      this.isLoadingWinrate = false;
    } else {
      this.isLoadingWinrate = true;
    }

    const winrate = await nonSyncMakePredict({
      ...req,
      turn: this.parentState.turn,
    })
      .then((res) => {
        this.setWinRateStack.pop();
        this.winrateLoadingQueue.dequeue();

        this.isActivateStep2 = true;
        this.history_id = res.data.result.history_id;

        if (this.selectedTeam === 'blue') {
          return res.data.result.winrate.winrate;
        } else {
          return -res.data.result.winrate.winrate;
        }
      })
      .catch((err) => {
        this.setWinRateStack.pop();
        alert('승률을 불러오는 중 오류가 발생했습니다.');
        return 0;
      });

    if (this.setWinRateStack.isEmpty()) {
      this.isLoadingWinrate = false;
    } else {
      this.isLoadingWinrate = true;
    }

    this.winrateState.data[0]['winrate'] = parseFloat(
      (winrate * 100).toFixed(0)
    );
  };

  public IndexToChamp = (index: number): VisTypes.Champ => {
    return (
      // eslint-disable-next-line eqeqeq
      this.champions.find((c) => c.index == index) || {
        index: 0,
        name: '',
        korName: '',
        image: '',
      }
    );
  };

  public setStage2State = async () => {
    const req = this.setRequest();
    this.recommendLoadingQueue.enqueue(req);
    this.loadingState.recommend = true;
    const winWeight = this.winrateWeightValue;
    const recommendWeight = 1 - winWeight;

    try {
      const recommendResponse = await makeRecommend({
        ...req,
        turn: this.parentState.turn,
      });
      this.recommendResultOut = recommendResponse;

      // recommend API 응답 처리
      const recommendChampsIndexes =
        recommendResponse.data.result.recommend.recommended;
      const order = recommendResponse.data.result.recommend.order;

      // 현재 턴이 레드팀 픽일 경우, 승률을 1에서 뺍니다.
      if ([1, 3, 5, 7, 8, 11, 12, 15, 16, 19].includes(0)) {
        recommendChampsIndexes.forEach((c: any) => {
          c.winpred = -c.winpred;
        });
      }

      let sortedByWinrate = [...recommendChampsIndexes].sort(
        (a: any, b: any) => a.winpred - b.winpred
      );

      const sortedByPickrate = [...recommendChampsIndexes].sort(
        (a: any, b: any) => a.pred_rate - b.pred_rate
      );

      const championScores = recommendChampsIndexes.map((champion) => {
        const winrateRank =
          sortedByWinrate.findIndex(
            (ch) => ch.champion_id === champion.champion_id
          ) + 1;
        const pickrateRank =
          sortedByPickrate.findIndex(
            (ch) => ch.champion_id === champion.champion_id
          ) + 1;
        const pickrateRankReversed = 35 - pickrateRank;

        const weightedScore =
          winrateRank * winWeight + pickrateRank * recommendWeight;
        const weightedScoreReversed =
          winrateRank * winWeight + pickrateRankReversed * recommendWeight;

        return {
          ...champion,
          weightedScore,
          weightedScoreReversed,
        };
      });

      // championScores에 따라 정렬
      const sortedByChampionScores = [...championScores].sort(
        (a, b) => b.weightedScore - a.weightedScore
      );

      const optimalChampion = championScores.sort(
        (a, b) => b.weightedScore - a.weightedScore
      )[0];

      const myosuChampion = championScores.sort(
        (a, b) => b.weightedScoreReversed - a.weightedScoreReversed
      )[0];

      this.recommendChampsState.recommendChamps = sortedByChampionScores
        .slice(0, 30)
        .map((c: any) => {
          const champ = this.IndexToChamp(c.champion_id);

          return {
            ...champ,
            winrate: parseFloat((c.winpred * 100).toFixed(1)),
            pickrate: parseFloat((c.pred_rate * 100).toFixed(1)),
            order: order[champ.index.toString()],
            positionPredict: [0.2, 0.2, 0.2, 0.2, 0.2],
            isMyosu: c.champion_id === myosuChampion?.champion_id,
            isBest: c.champion_id === optimalChampion?.champion_id,
          };
        });
    } catch (err) {
      this.recommendLoadingQueue.dequeue();
      alert('추천 챔피언을 불러오는 중 오류가 발생했습니다.');

      if (this.recommendLoadingQueue.isEmpty()) {
        this.loadingState.recommend = false;
      }
      return;
    }

    this.recommendLoadingQueue.dequeue();

    if (this.recommendLoadingQueue.isEmpty()) {
      this.loadingState.recommend = false;
    }
  };

  champions: VisTypes.Champ[] = [];

  constructor() {
    // makeAutoObservable을 사용하여 데코레이터 없이 상태 관리
    makeAutoObservable(this, {
      WidgetShowState: observable.deep,
      SimulateWinrateState: observable.deep,
    });
    this.loadChampions();
  }

  loadChampions = async () => {
    this.champions = await fetchChampionsData();
  };

  public setOverallStep = async (step: number) => {
    this.overallStep = step;

    if (step === 1) {
      this.setStage2State();
      this.setHistoryData(true);
    }

    if (step === 2) {
      await this.setHistoryData();
      await this.putHistoryFetch();
    }
  };

  public selectedTeam: 'blue' | 'red' | null = null;
  public setSelectedTeam = (team: 'blue' | 'red') => {
    this.selectedTeam = team;
    this.checkActivateStep2();
  };

  public isActivateStep2: boolean = false;
  private alreadyFetched: boolean = false;

  public checkActivateStep2 = () => {
    if (this.selectedTeam) {
      if (
        this.blueTeamPlayers.some((p) => !p || !p.name) ||
        this.redTeamPlayers.some((p) => !p || !p.name)
      ) {
        return;
      }
      if (this.blueTeamPlayers.map((p) => p.name).includes('')) {
        return;
      }
      if (this.redTeamPlayers.map((p) => p.name).includes('')) {
        return;
      }
      if (this.alreadyFetched) {
        return;
      }
      this.setStage1Winrates();
      this.alreadyFetched = true;
    }
  };

  public refreshWinrate = () => {
    this.alreadyFetched = false;
    this.checkActivateStep2();
  };

  public kvalue: number = 5;
  public pvalue: number = 53;
  public winrateWeightValue: number = 0.2;
  public patchVersion: string = 'latest';

  public setPatchVersion = (patchVersion: string) => {
    this.patchVersion = patchVersion;
  };

  public highlightedPlayer: {
    team: null | 'blue' | 'red';
    index: number;
  } = {
    team: null,
    index: -1,
  };
  public setHighlightedPlayer = (data: {
    team: 'blue' | 'red';
    index: number;
  }) => {
    this.highlightedPlayer = { ...data }; // 객체를 복사하여 상태를 교체
  };

  public setPlayer = (player: RoasterPlayer) => {
    if (this.highlightedPlayer.team === null) {
      return;
    }
    if (this.highlightedPlayer.team === 'blue') {
      // 배열 전체를 교체하는 방식으로 수정
      this.blueTeamPlayers = [
        ...this.blueTeamPlayers.slice(0, this.highlightedPlayer.index),
        player,
        ...this.blueTeamPlayers.slice(this.highlightedPlayer.index + 1),
      ];
    } else {
      // 배열 전체를 교체하는 방식으로 수정
      this.redTeamPlayers = [
        ...this.redTeamPlayers.slice(0, this.highlightedPlayer.index),
        player,
        ...this.redTeamPlayers.slice(this.highlightedPlayer.index + 1),
      ];
    }
    this.checkActivateStep2();
  };

  public blueTeamPlayers: RoasterPlayer[] = [
    {} as RoasterPlayer,
    {} as RoasterPlayer,
    {} as RoasterPlayer,
    {} as RoasterPlayer,
    {} as RoasterPlayer,
  ];
  public redTeamPlayers: RoasterPlayer[] = [
    {} as RoasterPlayer,
    {} as RoasterPlayer,
    {} as RoasterPlayer,
    {} as RoasterPlayer,
    {} as RoasterPlayer,
  ];
  public setBlueTeamPlayers = (players: RoasterPlayer[]) => {
    this.blueTeamPlayers = [...players];
    this.checkActivateStep2();
  };

  public setRedTeamPlayers = (players: RoasterPlayer[]) => {
    this.redTeamPlayers = [...players];
    this.checkActivateStep2();
  };

  public setBlueRoasters = (name: string) => {
    this.blue_roaster = name;
  };

  public setRedRoasters = (name: string) => {
    this.red_roaster = name;
  };

  public showOnOffHandler: boolean = false;
  public setShowOnOffHandler = () => {
    this.showOnOffHandler = !this.showOnOffHandler;
  };

  public WidgetShowState: {
    [key in VisTypes.WidgetTypes]: boolean;
  } = observable({
    draftboard: true,
    winrate: true,
    chooseChamps: true,
    recommendChamps: true,
    simulate: false,
    globalban: false,
    help: false,
  });

  public changeWidgetShowState = (type: VisTypes.WidgetTypes) => {
    this.WidgetShowState[type] = !this.WidgetShowState[type];
  };

  // 상태 업데이트를 처리하는 메서드
  public setWidgetState(type: VisTypes.WidgetTypes, newState: any) {
    const widget = this.parentState.widgets.find((w) => w.type === type);
    if (widget) {
      widget.state = { ...widget.state, ...newState };
    }
  }

  public setTurn(turn: number) {
    this.parentState.turn = turn;
  }

  public selectChamp(champ: VisTypes.Champ | null) {
    this.parentState.selectedChamp = champ;
    this.chooseChampsState.selectedChamp = champ;
    this.recommendChampsState.selectedChamp = champ;
  }

  public changeActivatePositionView() {
    this.draftboardState.positionActivated =
      !this.draftboardState.positionActivated;
  }

  public setChosenChampionPos = ({
    selectedPosition,
  }: {
    selectedPosition: number;
  }) => {
    this.draftboardState.chosenChampionPos = selectedPosition;
  };

  public setSelectedChamp = (champ: VisTypes.Champ | null) => {
    console.log(champ);
    if (this.parentState.turn === 20) {
      alert('픽이 완료되었습니다.');
      return;
    }

    this.chooseChampsState.selectedChamp = champ;
    this.parentState.selectedChamp = champ;
  };

  public isPickCompleted: boolean = false;

  public swapChampionsInSlots = (sourceIndex: number, targetIndex: number) => {
    if (!this.isPickCompleted) {
      return;
    }
    if (this.draftboardState.positionActivated) {
      return;
    }

    const pickKeys: (keyof VisTypes.Template)[] = [
      'bluepicktop',
      'bluepickjungle',
      'bluepickmid',
      'bluepickadc',
      'bluepicksupport',
      'redpicktop',
      'redpickjungle',
      'redpickmid',
      'redpickadc',
      'redpicksupport',
    ];

    const sourceKey = pickKeys[sourceIndex];
    const targetKey = pickKeys[targetIndex];
    if (sourceKey.slice(0, 1) !== targetKey.slice(0, 1)) {
      return;
    }

    // Swap the champions
    const sourceChamp = this.draftboardState.champTemplateBasic[sourceKey];
    const targetChamp = this.draftboardState.champTemplateBasic[targetKey];

    this.draftboardState.champTemplateBasic[sourceKey] = targetChamp;
    this.draftboardState.champTemplateBasic[targetKey] = sourceChamp;
  };

  public setDraftboardState = (position: any) => {
    const basic = this.draftboardState.champTemplateBasic;
    this.draftboardState.positionInfo = {
      0: {
        champ: basic.bluepicktop,
        top: position.blue_pos.pick1.top,
        jungle: position.blue_pos.pick1.jug,
        mid: position.blue_pos.pick1.mid,
        adc: position.blue_pos.pick1.adc,
        support: position.blue_pos.pick1.sub,
      },
      1: {
        champ: basic.bluepickjungle,
        top: position.blue_pos.pick2.top,
        jungle: position.blue_pos.pick2.jug,
        mid: position.blue_pos.pick2.mid,
        adc: position.blue_pos.pick2.adc,
        support: position.blue_pos.pick2.sub,
      },
      2: {
        champ: basic.bluepickmid,
        top: position.blue_pos.pick3.top,
        jungle: position.blue_pos.pick3.jug,
        mid: position.blue_pos.pick3.mid,
        adc: position.blue_pos.pick3.adc,
        support: position.blue_pos.pick3.sub,
      },
      3: {
        champ: basic.bluepickadc,
        top: position.blue_pos.pick4.top,
        jungle: position.blue_pos.pick4.jug,
        mid: position.blue_pos.pick4.mid,
        adc: position.blue_pos.pick4.adc,
        support: position.blue_pos.pick4.sub,
      },
      4: {
        champ: basic.bluepicksupport,
        top: position.blue_pos.pick5.top,
        jungle: position.blue_pos.pick5.jug,
        mid: position.blue_pos.pick5.mid,
        adc: position.blue_pos.pick5.adc,
        support: position.blue_pos.pick5.sub,
      },
      5: {
        champ: basic.redpicktop,
        top: position.red_pos.pick1.top,
        jungle: position.red_pos.pick1.jug,
        mid: position.red_pos.pick1.mid,
        adc: position.red_pos.pick1.adc,
        support: position.red_pos.pick1.sub,
      },
      6: {
        champ: basic.redpickjungle,
        top: position.red_pos.pick2.top,
        jungle: position.red_pos.pick2.jug,
        mid: position.red_pos.pick2.mid,
        adc: position.red_pos.pick2.adc,
        support: position.red_pos.pick2.sub,
      },
      7: {
        champ: basic.redpickmid,
        top: position.red_pos.pick3.top,
        jungle: position.red_pos.pick3.jug,
        mid: position.red_pos.pick3.mid,
        adc: position.red_pos.pick3.adc,
        support: position.red_pos.pick3.sub,
      },
      8: {
        champ: basic.redpickadc,
        top: position.red_pos.pick4.top,
        jungle: position.red_pos.pick4.jug,
        mid: position.red_pos.pick4.mid,
        adc: position.red_pos.pick4.adc,
        support: position.red_pos.pick4.sub,
      },
      9: {
        champ: basic.redpicksupport,
        top: position.red_pos.pick5.top,
        jungle: position.red_pos.pick5.jug,
        mid: position.red_pos.pick5.mid,
        adc: position.red_pos.pick5.adc,
        support: position.red_pos.pick5.sub,
      },
    };
  };

  public handleStates = (predictData: VisTypes.PredictResponse) => {
    const data = predictData.data.result;
  };

  private TurnSetPositionDict: { [key: number]: string } = {
    0: 'bluebantop',
    1: 'redbantop',
    2: 'bluebanjungle',
    3: 'redbanjungle',
    4: 'bluebanmid',
    5: 'redbanmid',
    6: 'bluepicktop',
    7: 'redpicktop',
    8: 'redpickjungle',
    9: 'bluepickjungle',
    10: 'bluepickmid',
    11: 'redpickmid',
    12: 'redbanadc',
    13: 'bluebanadc',
    14: 'redbansupport',
    15: 'bluebansupport',
    16: 'redpickadc',
    17: 'bluepickadc',
    18: 'bluepicksupport',
    19: 'redpicksupport',
  };

  public TemplateToPositionDict: { [key: string]: string } = {
    0: 'bluebantop',
    1: 'bluebanjungle',
    2: 'bluebanmid',
    3: 'bluebanadc',
    4: 'bluebansupport',
    5: 'redbantop',
    6: 'redbanjungle',
    7: 'redbanmid',
    8: 'redbanadc',
    9: 'redbansupport',
    10: 'bluepicktop',
    11: 'bluepickjungle',
    12: 'bluepickmid',
    13: 'bluepickadc',
    14: 'bluepicksupport',
    15: 'redpicktop',
    16: 'redpickjungle',
    17: 'redpickmid',
    18: 'redpickadc',
    19: 'redpicksupport',
  };

  public DictToPositionDict: { [key: string]: number } = {
    bluepicktop: 0,
    bluepickjungle: 1,
    bluepickmid: 2,
    bluepickadc: 3,
    bluepicksupport: 4,
    redpicktop: 5,
    redpickjungle: 6,
    redpickmid: 7,
    redpickadc: 8,
    redpicksupport: 9,
  };

  public nextTurn = () => {
    const turn = this.parentState.turn; // 현재 턴을 저장합니다.
    const key = this.TurnSetPositionDict[turn];

    if (
      this.winrateLoadingQueue.size() >= 3 ||
      this.draftLoadingQueue.size() >= 3 ||
      this.recommendLoadingQueue.size() >= 3
    ) {
      alert('요청이 너무 많습니다. 잠시 후 다시 시도해주세요.');
      return;
    }

    // 선택된 챔피언을 드래프트 보드에 업데이트합니다.
    this.draftboardState.champTemplateBasic[key as keyof VisTypes.Template] =
      this.parentState.selectedChamp;

    if (turn === 19) {
      // 20턴이 되면 드래프트가 종료됩니다.
      this.isPickCompleted = true;
    }

    if (turn === 20) {
      this.isPickCompleted = true;
      return;
    }

    const req = this.setRequest();

    // 현재 턴의 선택된 챔피언을 저장합니다.
    const selectedChamp = this.parentState.selectedChamp;

    // 선택된 챔피언 상태를 초기화합니다.
    this.parentState.selectedChamp = null;
    this.chooseChampsState.selectedChamp = null;
    try {
      this.recommendChampsState.selectedChamp = null;
    } catch {
      console.log('error');
    }

    // API 호출을 비동기로 처리합니다.
    this.fetchWinrateAndRecommendations(req, turn, selectedChamp);

    // 턴을 증가시킵니다.
    this.parentState.turn += 1;
  };

  public updateRecommendation = ({
    recommendResult,
    turn,
    useDefault,
  }: {
    recommendResult: any;
    turn: number;
    useDefault?: boolean;
  }) => {
    const recommendResponse = recommendResult;
    const recommendChampsIndexes =
      recommendResponse.data.result.recommend.recommended;
    const order = recommendResponse.data.result.recommend.order;

    let winWeight = this.winrateWeightValue;
    let recommendWeight = 1 - winWeight;

    if (useDefault && this.recommendResetWeight) {
      winWeight = 1 - this.turnWeight[turn];
      this.setWinrateWeightValue(winWeight * 100);
      recommendWeight = 1 - winWeight;
    } else {
      winWeight = this.winrateWeightValue;
      recommendWeight = 1 - winWeight;
    }
    const globalBan = this.globalBanState.globalBan.map((champ) => champ.index);

    const sortedByWinrate = [...recommendChampsIndexes].sort(
      (a, b) => a.winpred - b.winpred
    );

    const sortedByPickrate = [...recommendChampsIndexes].sort(
      (a: any, b: any) => a.pred_rate - b.pred_rate
    );

    const championScores = recommendChampsIndexes.map((champion: any) => {
      const winrateRank =
        sortedByWinrate.findIndex(
          (ch) => ch.champion_id === champion.champion_id
        ) + 1;
      const pickrateRank =
        sortedByPickrate.findIndex(
          (ch) => ch.champion_id === champion.champion_id
        ) + 1;
      const pickrateRankReversed = 35 - pickrateRank;

      const weightedScore =
        winrateRank * winWeight + pickrateRank * recommendWeight;
      const weightedScoreReversed =
        winrateRank * winWeight + pickrateRankReversed * recommendWeight;

      const weightedScoreForOpt =
        winrateRank * (1 - this.turnWeight[turn + 1]) +
        pickrateRank * this.turnWeight[turn + 1];

      const weightedScoreReversedForOpt =
        winrateRank * (1 - this.turnWeight[turn + 1]) +
        pickrateRankReversed * this.turnWeight[turn + 1];

      return {
        ...champion,
        weightedScore,
        weightedScoreReversed,
        weightedScoreForOpt,
        weightedScoreReversedForOpt,
      };
    });

    const sortedByChampionScores = [...championScores].sort(
      (a, b) => b.weightedScore - a.weightedScore
    );

    const optimalChampion = [...championScores].sort(
      (a: any, b: any) => b.weightedScoreForOpt - a.weightedScoreForOpt
    )[0];

    const myosuChampion = [...championScores].sort(
      (a: any, b: any) =>
        b.weightedScoreReversedForOpt - a.weightedScoreReversedForOpt
    )[0];

    this.recommendChampsState.recommendChamps = sortedByChampionScores
      .filter((c: any) => {
        const champ = this.IndexToChamp(c.champion_id);
        return !globalBan.includes(champ.index);
      })
      .slice(0, 30)
      .map((c: any) => {
        const champ = this.IndexToChamp(c.champion_id);

        return {
          ...champ,
          winrate: parseFloat((c.winpred * 100).toFixed(1)),
          pickrate: parseFloat((c.pred_rate * 100).toFixed(1)),
          order: order[champ.index.toString()],
          positionPredict: [0.2, 0.2, 0.2, 0.2, 0.2],
          isMyosu: c.champion_id === myosuChampion?.champion_id,
          isBest: c.champion_id === optimalChampion?.champion_id,
        };
      });
  };

  public recommendResultOut: any = null;

  // 비동기 API 호출을 처리하는 새로운 메서드를 만듭니다.
  private fetchWinrateAndRecommendations = async (
    req: any,
    turn: number,
    selectedChamp: VisTypes.Champ | null
  ) => {
    this.allTurnStateHistory[turn + 1] = {
      draftboard: loadash.cloneDeep(this.draftboardState),
      recommendChamp: null,
    };

    this.winrateLoadingQueue.enqueue(req);
    this.draftLoadingQueue.enqueue(req);
    this.recommendLoadingQueue.enqueue(req);

    this.loadingState.winrate = true;
    this.loadingState.draftboard = true;
    this.loadingState.recommend = true;

    try {
      console.log(this.selectedTeam);
      // winrate API와 recommend API를 개별적으로 처리
      const [winrateResult, recommendResult] = await Promise.allSettled([
        makePredictionWithIds({
          ...req,
          turn: turn + 1,
          history_id: this.history_id,
        }),
        makeRecommendWithIds({
          ...req,
          turn: turn + 1,
          history_id: this.history_id,
        }),
      ]);

      // winrate API 응답 처리
      if (winrateResult.status === 'fulfilled') {
        const winrateResponse = winrateResult.value;
        const winrate = parseFloat(
          (winrateResponse.data.result.winrate.winrate * 100).toFixed(1)
        );
        const pickrate = parseFloat(
          (winrateResponse.data.result.predrate.pick_rate * 100).toFixed(1)
        );
        const pickorder = winrateResponse.data.result.predrate.pick_order;
        const positions = winrateResponse.data.result.position;
        this.setDraftboardState(positions);
        if (this.selectedTeam === 'red') {
          this.updateWinrateState(
            turn,
            selectedChamp,
            parseFloat((-winrate).toFixed(1)),
            pickrate,
            pickorder,
            positions
          );
        } else {
          this.updateWinrateState(
            turn,
            selectedChamp,
            winrate,
            pickrate,
            pickorder,
            positions
          );
        }

        this.updateDraftBoardWithPosition(positions, turn);

        this.winrateLoadingQueue.dequeue();
        this.draftLoadingQueue.dequeue();

        if (this.winrateLoadingQueue.isEmpty()) {
          this.loadingState.winrate = false;
        }
        if (this.draftLoadingQueue.isEmpty()) {
          this.loadingState.draftboard = false;
        }
      } else {
        console.error('Winrate API failed:', winrateResult.reason);
        alert('승률 정보를 불러오는 중 오류가 발생했습니다.');
        this.winrateLoadingQueue.dequeue();
        this.draftLoadingQueue.dequeue();
        if (this.winrateLoadingQueue.isEmpty()) {
          this.loadingState.winrate = false;
        }
        if (this.draftLoadingQueue.isEmpty()) {
          this.loadingState.draftboard = false;
        }
      }

      // recommend API 응답 처리
      if (recommendResult.status === 'fulfilled') {
        if (!this.recommendSideIsChoosing) {
          if (this.selectedTeam === 'red') {
            recommendResult.value = {
              ...recommendResult.value,
              data: {
                ...recommendResult.value.data,
                result: {
                  ...recommendResult.value.data.result,
                  recommend: {
                    ...recommendResult.value.data.result.recommend,
                    recommended:
                      recommendResult.value.data.result.recommend.recommended.map(
                        (c: any) => ({
                          ...c,
                          winpred: -c.winpred,
                        })
                      ),
                  },
                },
              },
            };
          }
        } else {
          if ([1, 3, 5, 7, 8, 11, 12, 14, 16, 19].includes(turn + 1)) {
            recommendResult.value = {
              ...recommendResult.value,
              data: {
                ...recommendResult.value.data,
                result: {
                  ...recommendResult.value.data.result,
                  recommend: {
                    ...recommendResult.value.data.result.recommend,
                    recommended:
                      recommendResult.value.data.result.recommend.recommended.map(
                        (c: any) => ({
                          ...c,
                          winpred: -c.winpred,
                        })
                      ),
                  },
                },
              },
            };
          }
        }
        this.recommendResultOut = recommendResult.value;

        this.updateRecommendation({
          recommendResult: recommendResult.value,
          turn: turn,
          useDefault: true,
        });

        this.recommendLoadingQueue.dequeue();

        this.allTurnStateHistory[turn + 1] = {
          ...this.allTurnStateHistory[turn + 1],
          recommendChamp: this.recommendChampsState,
        };

        if (this.recommendLoadingQueue.isEmpty()) {
          this.loadingState.recommend = false;
        }
      } else {
        console.error('Recommend API failed:', recommendResult.reason);
        alert('추천 정보를 불러오는 중 오류가 발생했습니다.');
        this.recommendLoadingQueue.dequeue();
        if (this.recommendLoadingQueue.isEmpty()) {
          this.loadingState.recommend = false;
        }
      }
    } catch (error) {
      console.error(`Error fetching data for turn ${turn}:`, error);
    }

    if (turn >= 1) {
      this.backActivated = true;
    }
  };

  private updateDraftBoardWithPosition = (
    positionInfo: VisTypes.ResponsePositions,
    turn: number
  ) => {
    //key of champTemplateWithPosition
    let tempDict: VisTypes.Template = {
      ...this.draftboardState.champTemplateBasic,
      bluepicktop: null,
      bluepickjungle: null,
      bluepickmid: null,
      bluepickadc: null,
      bluepicksupport: null,
      redpicktop: null,
      redpickjungle: null,
      redpickmid: null,
      redpickadc: null,
      redpicksupport: null,
    };
    const top = [] as number[];
    const jug = [] as number[];
    const mid = [] as number[];
    const adc = [] as number[];
    const sub = [] as number[];
    Object.values(positionInfo.blue_pos).forEach((pick, index) => {
      top.push(pick.top);
      jug.push(pick.jug);
      mid.push(pick.mid);
      adc.push(pick.adc);
      sub.push(pick.sub);
    });
    const maxTop = top.indexOf(Math.max(...top));
    const maxJug = jug.indexOf(Math.max(...jug));
    const maxMid = mid.indexOf(Math.max(...mid));
    const maxAdc = adc.indexOf(Math.max(...adc));
    const maxSub = sub.indexOf(Math.max(...sub));

    const dict: { [key: number]: string } = {
      0: 'top',
      1: 'jungle',
      2: 'mid',
      3: 'adc',
      4: 'support',
    };
    tempDict.bluepicktop =
      this.draftboardState.champTemplateBasic[
        `bluepick${dict[maxTop]}` as keyof VisTypes.Template
      ];
    tempDict.bluepickjungle =
      this.draftboardState.champTemplateBasic[
        `bluepick${dict[maxJug]}` as keyof VisTypes.Template
      ];
    tempDict.bluepickmid =
      this.draftboardState.champTemplateBasic[
        `bluepick${dict[maxMid]}` as keyof VisTypes.Template
      ];
    tempDict.bluepickadc =
      this.draftboardState.champTemplateBasic[
        `bluepick${dict[maxAdc]}` as keyof VisTypes.Template
      ];
    tempDict.bluepicksupport =
      this.draftboardState.champTemplateBasic[
        `bluepick${dict[maxSub]}` as keyof VisTypes.Template
      ];

    // flush array
    top.length = 0;
    jug.length = 0;
    mid.length = 0;
    adc.length = 0;
    sub.length = 0;

    Object.values(positionInfo.red_pos).forEach((pick, index) => {
      top.push(pick.top);
      jug.push(pick.jug);
      mid.push(pick.mid);
      adc.push(pick.adc);
      sub.push(pick.sub);
    });

    const maxTopRed = top.indexOf(Math.max(...top));
    const maxJugRed = jug.indexOf(Math.max(...jug));
    const maxMidRed = mid.indexOf(Math.max(...mid));
    const maxAdcRed = adc.indexOf(Math.max(...adc));
    const maxSubRed = sub.indexOf(Math.max(...sub));

    tempDict.redpicktop =
      this.draftboardState.champTemplateBasic[
        `redpick${dict[maxTopRed]}` as keyof VisTypes.Template
      ];
    tempDict.redpickjungle =
      this.draftboardState.champTemplateBasic[
        `redpick${dict[maxJugRed]}` as keyof VisTypes.Template
      ];
    tempDict.redpickmid =
      this.draftboardState.champTemplateBasic[
        `redpick${dict[maxMidRed]}` as keyof VisTypes.Template
      ];
    tempDict.redpickadc =
      this.draftboardState.champTemplateBasic[
        `redpick${dict[maxAdcRed]}` as keyof VisTypes.Template
      ];
    tempDict.redpicksupport =
      this.draftboardState.champTemplateBasic[
        `redpick${dict[maxSubRed]}` as keyof VisTypes.Template
      ];

    this.draftboardState.champTemplateWithPosition = {
      ...tempDict,
    };

    if (this.parentState.turn - 1 <= 5) {
      this.draftboardState.chosenChampionPos = 0;
    } else if (
      this.parentState.turn - 1 >= 12 &&
      this.parentState.turn - 1 <= 15
    ) {
      this.draftboardState.chosenChampionPos = 7;
    } else {
      this.draftboardState.chosenChampionPos =
        this.DictToPositionDict[
          this.TurnSetPositionDict[this.parentState.turn - 1]
        ];
    }
    if (this.draftboardState.chosenChampionPos === undefined) {
      this.draftboardState.chosenChampionPos = 0;
    }

    this.allTurnStateHistory[turn + 1].draftboard!.champTemplateWithPosition = {
      ...tempDict,
    };
  };

  // winrateState를 업데이트하는 메서드를 분리합니다.
  private updateWinrateState = (
    turn: number,
    champ: VisTypes.Champ | null,
    winrate: number,
    pick_rate: number,
    pick_order: number,
    positions: any
  ) => {
    if (turn <= 5) {
      this.winrateState.data[turn + 1] = {
        turn: turn + 1,
        winrate: winrate,
        pickrate: pick_rate,
        pickorder: pick_order,
        champ: champ!,
        recommendations: [],
        positionPredict: [0, 0, 0, 0, 0],
      };
    } else if (turn >= 12 && turn <= 15) {
      this.winrateState.data[turn + 1] = {
        turn: turn + 1,
        winrate: winrate,
        pickrate: pick_rate,
        pickorder: pick_order,
        champ: champ!,
        recommendations: [],
        positionPredict: [0, 0, 0, 0, 0],
      };
    } else {
      const splited = this.TurnSetPositionDict[turn].split('pick');
      const posToPickNDict = {
        top: 'pick1',
        jungle: 'pick2',
        mid: 'pick3',
        adc: 'pick4',
        support: 'pick5',
      };

      let positionPredList: number[] = [0, 0, 0, 0, 0];

      if (splited[0] === 'blue') {
        const positionPredBlob =
          positions.blue_pos[
            posToPickNDict[splited[1] as keyof typeof posToPickNDict]
          ];

        positionPredList = [
          positionPredBlob.top,
          positionPredBlob.jug,
          positionPredBlob.mid,
          positionPredBlob.adc,
          positionPredBlob.sub,
        ];
      } else {
        const positionPredBlob =
          positions.red_pos[
            posToPickNDict[splited[1] as keyof typeof posToPickNDict]
          ];

        positionPredList = [
          positionPredBlob.top,
          positionPredBlob.jug,
          positionPredBlob.mid,
          positionPredBlob.adc,
          positionPredBlob.sub,
        ];
      }
      this.winrateState.data[turn + 1] = {
        turn: turn + 1,
        winrate: winrate,
        pickrate: pick_rate,
        pickorder: pick_order,
        champ: champ!,
        recommendations: [],
        positionPredict: positionPredList,
      };
    }
  };

  public setKValue = (value: number) => {
    this.kvalue = value;
  };

  public setPValue = (value: number) => {
    this.pvalue = value;
  };

  public setWinrateWeightValue = (value: number) => {
    this.winrateWeightValue = value / 100;
  };

  public historyData = {} as NewHistoryRecord;

  public convertToMetadata = (
    data: any
  ): {
    metadata: VisTypes.Metadata[];
    teamMetadata: VisTypes.TeamMetadata;
  } => {
    // 포지션 키와 위치 매핑
    const positionMap: { [key: string]: VisTypes.Metadata['position'] } = {
      top: '탑',
      jungle: '정글',
      mid: '미드',
      adc: '원딜',
      support: '서포터',
    };

    // 메타데이터 생성
    const metadata: VisTypes.Metadata[] = Object.keys(positionMap).map(
      (key) => {
        const positionData = data[key];

        return {
          position: positionMap[key],
          exp: positionData?.champExperience['0'],
          cs: positionData?.minions['0'],
          dpm: positionData?.dpm['0'],
          gold: positionData?.gold['0'],
          kda: positionData?.kda['0'],
          vision: positionData?.visions['0'],
        };
      }
    );

    // 팀 메타데이터 생성
    const teamData = data.team;
    const teamMetadata: VisTypes.TeamMetadata = {
      herald: [teamData.herald['0'], teamData.herald['1']],
      dragon: [teamData.dragon['0'], teamData.dragon['1']],
      baron: [teamData.baron['0'], teamData.baron['1']],
      soul: [teamData.soul['0'], teamData.soul['1'], teamData.soul['2']],
      elder: [teamData.elder['0'], teamData.elder['1'], teamData.elder['2']],
    };
    return { metadata, teamMetadata };
  };

  public setHistoryData = async (isNotAll?: boolean) => {
    let winrateResponse = null;

    if (!isNotAll) {
      const req = this.setRequest();

      // winrate API 호출
      winrateResponse = await nonSyncMakePredict({
        ...req,
        turn: this.parentState.turn,
      }).then((winrateresponse) => {
        const metadata = winrateresponse.data.result.metadata;
        return metadata;
      });
    }

    this.historyData = {
      date: new Date(Date.now()).toLocaleString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }),
      type: 'banpick',
      blueTeam: {
        players: [
          {
            line: 'top',
            champion: this.draftboardState.champTemplateWithPosition.bluepicktop
              ?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .bluepicktop?.image as string,
            id: 1,
            image: 'player1.png',
            name: 'Player1',
          },
          {
            line: 'jungle',
            champion: this.draftboardState.champTemplateWithPosition
              .bluepickjungle?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .bluepickjungle?.image as string,
            id: 2,
            image: 'player2.png',
            name: 'Player2',
          },
          {
            line: 'mid',
            champion: this.draftboardState.champTemplateWithPosition.bluepickmid
              ?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .bluepickmid?.image as string,
            id: 3,
            image: 'player3.png',
            name: 'Player3',
          },
          {
            line: 'adc',
            champion: this.draftboardState.champTemplateWithPosition.bluepickadc
              ?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .bluepickadc?.image as string,
            id: 4,
            image: 'player4.png',
            name: 'Player4',
          },
          {
            line: 'support',
            champion: this.draftboardState.champTemplateWithPosition
              .bluepicksupport?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .bluepicksupport?.image as string,
            id: 5,
            image: 'player5.png',
            name: 'Player5',
          },
        ],
      },
      redTeam: {
        players: [
          {
            line: 'top',
            champion: this.draftboardState.champTemplateWithPosition.redpicktop
              ?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .redpicktop?.image as string,
            id: 1,
            image: 'player1.png',
            name: 'Player1',
          },
          {
            line: 'jungle',
            champion: this.draftboardState.champTemplateWithPosition
              .redpickjungle?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .redpickjungle?.image as string,
            id: 2,
            image: 'player2.png',
            name: 'Player2',
          },
          {
            line: 'mid',
            champion: this.draftboardState.champTemplateWithPosition.redpickmid
              ?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .redpickmid?.image as string,
            id: 3,
            image: 'player3.png',
            name: 'Player3',
          },
          {
            line: 'adc',
            champion: this.draftboardState.champTemplateWithPosition.redpickadc
              ?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .redpickadc?.image as string,
            id: 4,
            image: 'player4.png',
            name: 'Player4',
          },
          {
            line: 'support',
            champion: this.draftboardState.champTemplateWithPosition
              .redpicksupport?.name as string,
            championImage: this.draftboardState.champTemplateWithPosition
              .redpicksupport?.image as string,
            id: 5,
            image: 'player5.png',
            name: 'Player5',
          },
        ],
      },
      blueTeamWinRate: parseFloat(
        (this.winrateState.data[20]?.winrate * 100).toFixed(1)
      ),
      redTeamWinRate: parseFloat(
        (-this.winrateState.data[20]?.winrate * 100).toFixed(1)
      ),
      players: [
        {
          line: 'top',
          champion: this.draftboardState.champTemplateWithPosition.bluepicktop
            ?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .bluepicktop?.image as string,
          id: 1,
          image: 'player1.png',
          name: this.blueTeamPlayers[0].name,
        },
        {
          line: 'jungle',
          champion: this.draftboardState.champTemplateWithPosition
            .bluepickjungle?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .bluepickjungle?.image as string,
          id: 2,
          image: 'player2.png',
          name: this.blueTeamPlayers[1].name,
        },
        {
          line: 'mid',
          champion: this.draftboardState.champTemplateWithPosition.bluepickmid
            ?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .bluepickmid?.image as string,
          id: 3,
          image: 'player3.png',
          name: this.blueTeamPlayers[2].name,
        },
        {
          line: 'adc',
          champion: this.draftboardState.champTemplateWithPosition.bluepickadc
            ?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .bluepickadc?.image as string,
          id: 4,
          image: 'player4.png',
          name: this.blueTeamPlayers[3].name,
        },
        {
          line: 'support',
          champion: this.draftboardState.champTemplateWithPosition
            .bluepicksupport?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .bluepicksupport?.image as string,
          id: 5,
          image: 'player5.png',
          name: this.blueTeamPlayers[4].name,
        },
        {
          line: 'top',
          champion: this.draftboardState.champTemplateWithPosition.redpicktop
            ?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .redpicktop?.image as string,
          id: 1,
          image: 'player1.png',
          name: this.redTeamPlayers[0].name,
        },
        {
          line: 'jungle',
          champion: this.draftboardState.champTemplateWithPosition.redpickjungle
            ?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .redpickjungle?.image as string,
          id: 2,
          image: 'player2.png',
          name: this.redTeamPlayers[1].name,
        },
        {
          line: 'mid',
          champion: this.draftboardState.champTemplateWithPosition.redpickmid
            ?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .redpickmid?.image as string,
          id: 3,
          image: 'player3.png',
          name: this.redTeamPlayers[2].name,
        },
        {
          line: 'adc',
          champion: this.draftboardState.champTemplateWithPosition.redpickadc
            ?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .redpickadc?.image as string,
          id: 4,
          image: 'player4.png',
          name: this.redTeamPlayers[3].name,
        },
        {
          line: 'support',
          champion: this.draftboardState.champTemplateWithPosition
            .redpicksupport?.name as string,
          championImage: this.draftboardState.champTemplateWithPosition
            .redpicksupport?.image as string,
          id: 5,
          image: 'player5.png',
          name: this.redTeamPlayers[4].name,
        },
      ],
      graphData: this.winrateState.data,
      metadata: winrateResponse
        ? this.convertToMetadata(winrateResponse).metadata
        : null,
      teamMetadata: winrateResponse
        ? this.convertToMetadata(winrateResponse).teamMetadata
        : null,
    };
  };

  public putHistoryFetch = async () => {
    await putHistory({
      key: Date.now(),
      currentTurn: this.parentState.turn,
      patch: this.patchVersion,
      category: 'total',
      blue_roaster: null,
      red_roaster: null,
      playerList: this.blueTeamPlayers
        ?.map((player) => {
          return player.id;
        })
        .concat(
          this.redTeamPlayers?.map((player) => {
            return player.id;
          })
        ),
      templates: null,
      recommendChamps: null,
      status: 'completed',
      accuracy: 0,
      champions: null,
      is_blue_win: null,
      result: this.historyData,
    });
  };

  public searchMode: boolean = false;
  public setSearchMode = () => {
    this.searchMode = !this.searchMode;
  };

  public simPosToggle: boolean = false;
  public setSimPosToggle = () => {
    if (!this.isSimulatePickCompleted) {
      return;
    }
    this.simPosToggle = !this.simPosToggle;
  };

  public simulateBluePlayers = this.blueTeamPlayers;
  public simulateRedPlayers = this.redTeamPlayers;

  public simulateTurn = 0;
  public simulateSelectedChamp: VisTypes.Champ | null = null;
  public simulateSelectedChampPosition: number | null = null;
  public simulateSelectedPlayerPosition: {
    team: 'blue' | 'red';
    index: number;
  } | null = null;

  public isSimulatePickCompleted: boolean = false;
  public isSimulationLoading: boolean = false;

  public simulationDraftboardState: VisTypes.DraftboardState = {
    chosenChampionPos: 0,
    positionActivated: false,
    positionInfo: {
      0: { champ: null, top: 0, jungle: 0, mid: 0, adc: 0, support: 0 },
    },
    champTemplateBasic: {
      redbantop: null,
      redbanjungle: null,
      redbanmid: null,
      redbanadc: null,
      redbansupport: null,
      bluebantop: null,
      bluebanjungle: null,
      bluebanmid: null,
      bluebanadc: null,
      bluebansupport: null,
      bluepicktop: null,
      bluepickjungle: null,
      bluepickmid: null,
      bluepickadc: null,
      bluepicksupport: null,
      redpicktop: null,
      redpickjungle: null,
      redpickmid: null,
      redpickadc: null,
      redpicksupport: null,
    },
    champTemplateWithPosition: {
      redbantop: null,
      redbanjungle: null,
      redbanmid: null,
      redbanadc: null,
      redbansupport: null,
      bluebantop: null,
      bluebanjungle: null,
      bluebanmid: null,
      bluebanadc: null,
      bluebansupport: null,
      bluepicktop: null,
      bluepickjungle: null,
      bluepickmid: null,
      bluepickadc: null,
      bluepicksupport: null,
      redpicktop: null,
      redpickjungle: null,
      redpickmid: null,
      redpickadc: null,
      redpicksupport: null,
    },
  };

  public SimulateWinrateState: VisTypes.SimulateWinrateState = {
    data: {
      0: {
        turn: 0,
        winrate: 0,
        pickrate: 0,
        pickorder: 0,
        champ: {
          index: 0,
          name: '',
          image: '',
          korName: '',
        },
        recommendations: [],
        positionPredict: [0, 0, 0, 0, 0],
        isSimulated: false,
      },
    },
  };

  public setFixedChampion = (
    champ: VisTypes.Champ,
    team: 'blue' | 'red',
    position: 'top' | 'jungle' | 'mid' | 'adc' | 'support'
  ) => {
    const key = `${team}pick${position}` as keyof VisTypes.Template;
    this.simulationDraftboardState.champTemplateBasic[key] = champ;
  };

  public setFixedPlayers = (
    player: RoasterPlayer,
    team: 'blue' | 'red',
    position: 'top' | 'jungle' | 'mid' | 'adc' | 'support'
  ) => {
    const index =
      position === 'top'
        ? 0
        : position === 'jungle'
        ? 1
        : position === 'mid'
        ? 2
        : position === 'adc'
        ? 3
        : 4;
    if (team === 'blue') {
      this.simulateBluePlayers[index] = player;
    } else {
      this.simulateRedPlayers[index] = player;
    }
  };

  public initializeSimulate = () => {
    this.simulateTurn = this.parentState.turn;
    this.simulateBluePlayers = [...this.blueTeamPlayers];
    this.simulateRedPlayers = [...this.redTeamPlayers];
    this.simulationDraftboardState.champTemplateBasic = {
      ...this.draftboardState.champTemplateBasic,
    };
    this.simulationDraftboardState.champTemplateWithPosition = {
      ...this.draftboardState.champTemplateWithPosition,
    };
    this.simulateTurn = this.parentState.turn;
    this.isSimulationLoading = false;
    this.SimulateWinrateState = {
      data: {
        0: {
          turn: 0,
          winrate: 0,
          pickrate: 0,
          pickorder: 0,
          champ: {
            index: 0,
            name: '',
            image: '',
            korName: '',
          },
          recommendations: [],
          positionPredict: [0, 0, 0, 0, 0],
          isSimulated: false,
        },
      },
    };
  };

  public simulateSetSelectedChamp = (champ: VisTypes.Champ | null) => {
    if (this.simulateSelectedChampPosition === null) {
      return;
    }

    this.simulationDraftboardState.champTemplateBasic[
      this.TemplateToPositionDict[
        this.simulateSelectedChampPosition
      ] as keyof VisTypes.Template
    ] = champ;
  };

  public setSelectedPlayerPosition = (team: 'blue' | 'red', index: number) => {
    this.simulateSelectedPlayerPosition = { team, index };
  };

  public simulateSetSelectedPlayer = (player: RoasterPlayer) => {
    if (this.simulateSelectedPlayerPosition === null) {
      return;
    }

    if (this.simulateSelectedPlayerPosition.team === 'blue') {
      this.simulateBluePlayers[this.simulateSelectedPlayerPosition.index] =
        player;
    } else {
      this.simulateRedPlayers[this.simulateSelectedPlayerPosition.index] =
        player;
    }
  };

  public setSimulateSelectedChampPosition = (position: number) => {
    // 포지션에 해당하는 draftboard의 포지션이 null이면 선택가능
    // 0~20 position
    const key = this.TemplateToPositionDict[position];
    if (
      this.simulationDraftboardState.champTemplateBasic[
        key as keyof VisTypes.Template
      ] !== null
    ) {
      return;
    }

    this.simulateSelectedChampPosition = position;
  };

  public simulationActivated: boolean = false;
  public simulationStopActivated: boolean = true;

  private simulationTimeout: NodeJS.Timeout | null = null;

  private updateSimulateWinrateState = (
    turn: number,
    champ: VisTypes.Champ | null,
    winrate: number,
    pick_rate: number,
    pick_order: number,
    positions: any
  ) => {
    if (turn <= 5) {
      this.SimulateWinrateState.data[turn + 1] = {
        turn: turn + 1,
        winrate: winrate,
        pickrate: pick_rate,
        pickorder: pick_order,
        champ: champ!,
        recommendations: [],
        positionPredict: [0, 0, 0, 0, 0],
        isSimulated: true,
      };
    } else if (turn >= 12 && turn <= 15) {
      this.SimulateWinrateState.data[turn + 1] = {
        turn: turn + 1,
        winrate: winrate,
        pickrate: pick_rate,
        pickorder: pick_order,
        champ: champ!,
        recommendations: [],
        positionPredict: [0, 0, 0, 0, 0],
        isSimulated: true,
      };
    } else {
      this.SimulateWinrateState.data[turn + 1] = {
        turn: turn + 1,
        winrate: winrate,
        pickrate: pick_rate,
        pickorder: pick_order,
        champ: champ!,
        recommendations: [],
        positionPredict: [0, 0, 0, 0, 0, 0],
        isSimulated: true,
      };
    }
  };

  // 시뮬레이션 시작 메서드
  public startSimulation = () => {
    if (this.simulationActivated) return; // 이미 실행 중이면 종료
    if (this.simulateTurn >= 20) return; // 이미 종료된 시뮬레이션이면 종료
    this.simulationActivated = true;
    this.simulationStopActivated = false;
    this.simulateSelectedChampPosition = null;
    this.simulateSelectedPlayerPosition = null;

    this.runSimulationStep();
  };

  // 시뮬레이션 중단 메서드
  public stopSimulation = () => {
    this.simulationActivated = false;
    this.simulationStopActivated = true;

    if (this.simulationTimeout) {
      clearTimeout(this.simulationTimeout);
      this.simulationTimeout = null;
    }
  };

  public redSimulateCustomWinWeight = 0.5;
  public blueSimulateCustomWinWeight = 0.5;

  public setRedSimulateCustomWinWeight = (value: number) => {
    this.redSimulateCustomWinWeight = value / 100;
  };

  public setBlueSimulateCustomWinWeight = (value: number) => {
    this.blueSimulateCustomWinWeight = value / 100;
  };

  public redSimulatePolicy = {
    optimized: false,
    winrate: false,
    pickrate: false,
    custom: false,
  };

  public blueSimulatePolicy = {
    optimized: false,
    winrate: false,
    pickrate: false,
    custom: false,
  };

  public initializeSimWidget = () => {
    this.redSimulatePolicy = {
      optimized: this.selectedTeam === 'red',
      winrate: false,
      pickrate: !(this.selectedTeam === 'red'),
      custom: false,
    };

    this.blueSimulatePolicy = {
      optimized: this.selectedTeam === 'blue',
      winrate: false,
      pickrate: !(this.selectedTeam === 'blue'),
      custom: false,
    };
  };

  public setRedSimulate = (key: string) => {
    this.redSimulatePolicy = {
      optimized: 'optimized' === key,
      winrate: 'winrate' === key,
      pickrate: 'pickrate' === key,
      custom: 'custom' === key,
    };
  };

  public setBlueSimulate = (key: string) => {
    this.blueSimulatePolicy = {
      optimized: 'optimized' === key,
      winrate: 'winrate' === key,
      pickrate: 'pickrate' === key,
      custom: 'custom' === key,
    };
  };

  // 시뮬레이션 단일 스텝 실행 메서드
  private runSimulationStep = async () => {
    if (!this.simulationActivated) return;

    const turnKey = this.TurnSetPositionDict[
      this.simulateTurn
    ] as keyof VisTypes.Template;
    const champ = this.simulationDraftboardState.champTemplateBasic[turnKey];

    if (this.simulateTurn >= 20) {
      this.stopSimulation();
      return;
    }

    if (champ !== null) {
      this.simulateTurn += 1;
      this.simulationTimeout = setTimeout(this.runSimulationStep, 100);
      return;
    }

    // 현재 드래프트 상태로 API 요청 생성
    const req = this.setSimulateRequest();
    this.isSimulationLoading = true;

    // 추천 API 호출
    try {
      const recommendResponse = await makeRecommend({
        ...req,
        turn: this.simulateTurn,
      });

      let winWeight = this.winrateWeightValue;
      let recommendWeight = 1 - winWeight;

      const recommendChampsIndexes =
        recommendResponse.data.result.recommend.recommended;

      // 현재 턴이 레드팀 픽일 경우, 승률을 1에서 뺍니다.
      if ([1, 3, 5, 7, 8, 11, 12, 14, 16, 19].includes(this.simulateTurn)) {
        recommendChampsIndexes.forEach((c: any) => {
          c.winpred = -c.winpred;
        });
        if (this.redSimulatePolicy.custom) {
          winWeight = this.redSimulateCustomWinWeight;
          recommendWeight = 1 - winWeight;
        } else if (this.redSimulatePolicy.winrate) {
          winWeight = 1;
          recommendWeight = 0;
        } else if (this.redSimulatePolicy.pickrate) {
          winWeight = 0;
          recommendWeight = 1;
        } else {
          winWeight = 1 - this.turnWeight[this.simulateTurn];
          recommendWeight = this.turnWeight[this.simulateTurn];
        }
      } else {
        if (this.blueSimulatePolicy.custom) {
          winWeight = this.blueSimulateCustomWinWeight;
          recommendWeight = 1 - winWeight;
        } else if (this.blueSimulatePolicy.winrate) {
          winWeight = 1;
          recommendWeight = 0;
        } else if (this.blueSimulatePolicy.pickrate) {
          winWeight = 0;
          recommendWeight = 1;
        } else {
          winWeight = 1 - this.turnWeight[this.simulateTurn];
          recommendWeight = this.turnWeight[this.simulateTurn];
        }
      }

      let sortedByWinrate = [...recommendChampsIndexes].sort(
        (a: any, b: any) => a.winpred - b.winpred
      );

      const sortedByPickrate = [...recommendChampsIndexes].sort(
        (a: any, b: any) => a.pred_rate - b.pred_rate
      );

      const championScores = recommendChampsIndexes.map((champion) => {
        const winrateRank =
          sortedByWinrate.findIndex(
            (ch) => ch.champion_id === champion.champion_id
          ) + 1;
        const pickrateRank =
          sortedByPickrate.findIndex(
            (ch) => ch.champion_id === champion.champion_id
          ) + 1;
        const pickrateRankReversed = 35 - pickrateRank;

        const weightedScore =
          winrateRank * winWeight + pickrateRank * recommendWeight;
        const weightedScoreReversed =
          winrateRank * winWeight + pickrateRankReversed * recommendWeight;

        return {
          ...champion,
          weightedScore,
          weightedScoreReversed,
        };
      });

      // championScores에 따라 정렬
      const sortedByChampionScores = [...championScores].sort(
        (a, b) => b.weightedScore - a.weightedScore
      );

      // 이미 선택된 챔피언들을 제외한 추천 챔피언 목록 생성
      const selectedChampionIndexes = this.getSelectedChampionIndexes();

      const globalBan = this.globalBanState.globalBan.map(
        (champ) => champ.index
      );

      const availableRecommendations = sortedByChampionScores.filter(
        (c: any) =>
          !selectedChampionIndexes.includes(c.champion_id) &&
          !globalBan.includes(c.champion_id)
      );

      if (availableRecommendations.length > 0) {
        const nextChampionId = availableRecommendations[0].champion_id;
        const nextChampion = this.IndexToChamp(nextChampionId);

        const winrate = availableRecommendations[0].winpred;
        const pick_rate = availableRecommendations[0].pred_rate;

        this.updateSimulateWinrateState(
          this.simulateTurn,
          nextChampion,
          this.selectedTeam === 'blue'
            ? parseFloat((winrate * 100).toFixed(1))
            : -parseFloat((winrate * 100).toFixed(1)),
          parseFloat((pick_rate * 100).toFixed(1)),
          0,
          null
        );

        // 다음 턴의 위치 키 가져오기
        const turnKey = this.TurnSetPositionDict[this.simulateTurn];

        // 시뮬레이션 드래프트 상태에 챔피언 추가
        this.simulationDraftboardState.champTemplateBasic[
          turnKey as keyof VisTypes.Template
        ] = { ...nextChampion, isSimulated: true };

        // 턴 증가
        this.simulateTurn += 1;
        this.isSimulationLoading = false;

        // 다음 스텝을 일정 시간 후에 실행
        this.simulationTimeout = setTimeout(this.runSimulationStep, 1000); // 1초 후 다음 스텝 실행
      } else {
        // 추천 가능한 챔피언이 없을 경우 시뮬레이션 중단
        this.stopSimulation();
        this.isSimulationLoading = false;
      }
    } catch (error) {
      console.error('시뮬레이션 중 오류 발생:', error);
      this.stopSimulation();
      this.isSimulationLoading = false;
    }
  };

  // 시뮬레이션을 위한 API 요청 생성 메서드
  private setSimulateRequest = () => {
    const patch = this.patchVersion === 'latest' ? '0.0' : this.patchVersion;
    const playerList = this.simulateBluePlayers
      .concat(this.simulateRedPlayers)
      .map((p) => p.id);
    const blue_roaster = null;
    const red_roaster = null;
    const current_champion = this.convertToCurrentChampion(
      this.simulationDraftboardState.champTemplateBasic,
      false
    );
    const current_ban_champion = this.convertToCurrentChampion(
      this.simulationDraftboardState.champTemplateBasic,
      true
    );

    const PReq = {
      patch: patch,
      playerList: playerList,
      blue_roaster: blue_roaster,
      red_roaster: red_roaster,
      current_champion: current_champion,
      current_ban_champion: current_ban_champion,
    };

    return PReq;
  };

  // 이미 선택된 챔피언들의 인덱스 목록을 반환하는 메서드
  private getSelectedChampionIndexes = (): number[] => {
    const champTemplate = this.simulationDraftboardState.champTemplateBasic;
    const selectedChamps = Object.values(champTemplate)
      .concat(this.globalBanState.globalBan)
      .filter((champ) => champ !== null)
      .map((champ) => (champ as VisTypes.Champ).index);
    return selectedChamps;
  };
}

export default RecommendVM;
