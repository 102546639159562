import { faHouse, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from '../../hooks/context/AuthContext';
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';

const SidebarContainer = styled.div<{ isOpen: boolean }>`
  font-size: 24px;
  white-space: nowrap;
  background: ${({ isOpen }) => (isOpen ? 'f8f8f8' : 'transparent')};
  max-width: ${({ isOpen }) => (isOpen ? '400px' : '50px')};
  padding: 20px ${({ isOpen }) => (isOpen ? '20px' : '10px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: max-width 0.3s ease, padding 0.3s ease;
  border-top: none !important;
  outline: none;
  border-right: ${({ isOpen }) =>
    isOpen ? '3px solid #f0f0f0' : '1px solid #f0f0f0'};
`;

const ToggleButton = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: ${({ isOpen }) => (isOpen ? 'flex-start' : 'center')};
  cursor: pointer;
`;

const SidebarTitle = styled.h1<{ isOpen: boolean }>`
  font-size: ${({ isOpen }) => (isOpen ? '1.5rem' : '0')};
  margin-bottom: 20px;
  margin-top: 30px;
  font-weight: 600;
  color: #00de9b;
  border-bottom: ${({ isOpen }) => (isOpen ? '2px solid #00DE9B' : 'none')};
  overflow: hidden;
  white-space: nowrap;
  transition: font-size 0.3s ease, border-bottom 0.3s ease;
`;

const SidebarHome = styled.div<{ isSelected: boolean; isOpen: boolean }>`
  font-size: 24px;
  color: ${({ isSelected }) => (isSelected ? 'white' : '#333')};
  background: ${({ isSelected }) => (isSelected ? '#000000' : 'transparent')};
  padding-left: ${({ isSelected, isOpen }) =>
    isSelected && isOpen ? '40px' : '0'};
  padding-right: ${({ isOpen }) => (isOpen ? '15px' : '0')};
  padding-top: ${({ isSelected }) => (isSelected ? '7px' : '0')};
  padding-bottom: ${({ isSelected }) => (isSelected ? '7px' : '0')};
  margin-top: 20px;
  margin-bottom: 0px;
  border-radius: ${({ isSelected }) =>
    isSelected ? '0px 16px 16px 0px' : '0'};
  transform: ${({ isSelected }) => (isSelected ? 'translateX(-40px)' : 'none')};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: padding-left 0.3s ease;
  gap: 15px;
  &:hover {
    color: ${({ isSelected }) => (isSelected ? 'white' : '#0057FF')};
  }
`;

const SidebarItem = styled.div<{ isSelected: boolean; isOpen?: boolean }>`
  color: ${({ isSelected }) => (isSelected ? 'white' : '#333')};
  background: ${({ isSelected }) => (isSelected ? '#000000' : 'transparent')};
  padding-left: ${({ isSelected, isOpen }) =>
    isSelected && isOpen ? '40px' : '0'};
  padding-right: ${({ isOpen }) => (isOpen ? '15px' : '0')};
  padding-top: ${({ isSelected }) => (isSelected ? '5px' : '0')};
  padding-bottom: ${({ isSelected }) => (isSelected ? '5px' : '0')};
  margin-top: 20px;
  border-radius: ${({ isSelected }) =>
    isSelected ? '0px 16px 16px 0px' : '0'};
  transform: ${({ isSelected }) => (isSelected ? 'translateX(-40px)' : 'none')};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: padding-left 0.3s ease;
  font-size: ${({ isOpen }) => (isOpen ? 'inherit' : '20px')};

  &:hover {
    color: ${({ isSelected }) => (isSelected ? 'white' : '#0057FF')};
  }
`;

const SidebarFooter = styled.div<{ isOpen: boolean }>`
  color: #333;
  cursor: pointer;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  font-size: 16px;
  &:hover {
    color: #0057ff;
  }
`;
const CloseOpenIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: #0057ff;
  }
`;
interface SidebarProps {
  selectedItem: string;
  initialOpen?: boolean;
}

export const SidebarMenu: React.FC<SidebarProps> = ({
  selectedItem,
  initialOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(initialOpen || false);
  const { clickLogOut } = useAuth();

  const location = useLocation();

  const handleNavigation = (path: string) => {
    const currentPath = location.pathname;
    if (currentPath === path) {
      window.location.reload();
    }

    if (currentPath === '/home') {
      // Home 페이지일 때는 뒤로가기가 활성화되도록 이동
      navigate(path);
    } else {
      // 그 외 페이지에서는 뒤로가기를 비활성화
      navigate(path, { replace: true });
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === 'ko' ? 'en' : 'ko';
    i18n.changeLanguage(newLang);
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
        <div>
          <ToggleButton onClick={toggleSidebar} isOpen={isOpen}>
            <CloseOpenIcon icon={isOpen ? faTimes : faBars} />
          </ToggleButton>
          <SidebarHome
            isSelected={selectedItem === '홈'}
            onClick={() => handleNavigation('/home')}
            isOpen={isOpen}
          >
            <FontAwesomeIcon icon={faHouse} />
            {isOpen && t(`menu.home`)}
          </SidebarHome>
          <SidebarTitle isOpen={isOpen}>{t(`menu.totalservice`)}</SidebarTitle>
          <SidebarItem
            isSelected={selectedItem === 'Dashboard'}
            onClick={() => handleNavigation('/dashboard')}
            isOpen={isOpen}
          >
            {isOpen && 'Dashboard'}
          </SidebarItem>
          <SidebarItem
            isSelected={selectedItem === '사용 기록 보기'}
            onClick={() => handleNavigation('/userdata/history')}
            isOpen={isOpen}
          >
            {isOpen && t(`menu.history`)}
          </SidebarItem>
          <SidebarTitle isOpen={isOpen}>{t(`menu.manageaccount`)}</SidebarTitle>
          <SidebarItem
            isSelected={selectedItem === '선수 정보 관리'}
            onClick={() => handleNavigation('/userdata/player')}
            isOpen={isOpen}
          >
            {isOpen && t(`menu.playerinfo`)}
          </SidebarItem>
          <SidebarItem
            isSelected={selectedItem === '로스터 등록'}
            onClick={() => handleNavigation('/userdata/roaster')}
            isOpen={isOpen}
          >
            {isOpen && t(`menu.roasterinfo`)}
          </SidebarItem>
          <SidebarItem
            isSelected={selectedItem === '계정 정보 변경'}
            onClick={() => handleNavigation('/userdata/private')}
            isOpen={isOpen}
          >
            {isOpen && t(`menu.accountinfo`)}
          </SidebarItem>
          <SidebarTitle isOpen={isOpen}>{t(`menu.langsettings`)}</SidebarTitle>
          <SidebarItem
            isSelected={selectedItem === '언어 설정'}
            onClick={toggleLanguage}
          >
            {isOpen && t(`header.language`)}
          </SidebarItem>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
            marginBottom: '20px',
          }}
        >
          <SidebarFooter isOpen={isOpen} onClick={() => clickLogOut()}>
            {t(`menu.logout`)}
          </SidebarFooter>
        </div>
      </div>
    </SidebarContainer>
  );
};
