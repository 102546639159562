import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import styled from 'styled-components';

const SidebarItem = styled.div<{ isSelected: boolean; isOpen?: boolean }>`
  color: ${({ isSelected }) => (isSelected ? 'white' : '#333')};
  background: ${({ isSelected }) => (isSelected ? '#000000' : 'transparent')};
  padding-left: ${({ isSelected, isOpen }) =>
    isSelected && isOpen ? '40px' : '0'};
  padding-right: ${({ isOpen }) => (isOpen ? '15px' : '0')};
  padding-top: ${({ isSelected }) => (isSelected ? '5px' : '0')};
  padding-bottom: ${({ isSelected }) => (isSelected ? '5px' : '0')};
  margin-top: 20px;
  border-radius: ${({ isSelected }) =>
    isSelected ? '0px 16px 16px 0px' : '0'};
  transform: ${({ isSelected }) => (isSelected ? 'translateX(-40px)' : 'none')};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: padding-left 0.3s ease;
  font-size: ${({ isOpen }) => (isOpen ? 'inherit' : '20px')};

  background: #f2f2f2;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    color: ${({ isSelected }) => (isSelected ? 'white' : '#0057FF')};
  }
`;

const LangToggle = () => {
  const toggleLanguage = () => {
    const newLang = i18n.language === 'ko' ? 'en' : 'ko';
    i18n.changeLanguage(newLang);
  };

  const { t } = useTranslation();

  return (
    <SidebarItem isSelected={false} onClick={toggleLanguage}>
      {t('header.language')}
    </SidebarItem>
  );
};

export default LangToggle;
