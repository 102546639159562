// src/pages/userdata/history/UserDataHistory.tsx
import { MenuArea } from '../../../components/mainMenu/MenuArea';
import { PlayerProvider } from '../../../hooks/context/PlayerContext';
import BanpickRecordItem from './component/BanResultSection';
import { useTranslation } from 'react-i18next';

const UserDataHistory = () => {
  const { t } = useTranslation();

  return (
    <PlayerProvider>
      <MenuArea
        title={t(`menu.history`)}
        selectedItem="사용 기록 보기"
        fullScreen={false}
        background="#F6F6F6"
      >
        <BanpickRecordItem />
      </MenuArea>
    </PlayerProvider>
  );
};

export default UserDataHistory;
