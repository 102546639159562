import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ImageWithName } from '../common/icon/ProfileIcon';
import { Input } from '../common/input/Input';
import useChampions from '../../hooks/useChampions';
import { Champ } from '../../pages/inference/recommend/type';
import { useTranslation } from 'react-i18next';

const PlayerSearchContainer = styled.div<{ containerHeight?: string }>`
  width: 75%;
  height: ${({ containerHeight }) => containerHeight};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #282828;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const SearchButton = styled.button`
  padding: 10px;
  font-size: 28px;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
`;

const PlayerGrid = styled.div`
  flex: 1;
  align-self: center;
  width: 90%;
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-auto-rows: 60px;
  gap: 10px;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
  align-items: grid-start;
  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    height: 20%;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
  }

  @media (max-width: 1200px) {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(55px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(65px, 1fr));
    gap: 5px;
  }
`;

const CustomInput = styled(Input)`
  background: transparent;
  font-size: 1.3rem;
  padding: 5px;
  color: white;
  width: 280px;
  border-bottom: 1px solid white;
`;

const SearchChampion = ({
  onChampionClick,
  containerHeight,
  cannotPickChampions,
  isPickCompleted,
}: {
  onChampionClick?: (champion: Champ) => void;
  containerHeight?: string;
  cannotPickChampions?: Champ[];
  isPickCompleted: boolean | null | undefined;
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const champions = useChampions();
  const [filteredChampions, setFilteredChampions] =
    useState<Champ[]>(champions);

  const searchChampions = useMemo(() => {
    if (searchTerm.trim() === '') {
      return champions;
    } else {
      const filtered = champions.filter(
        (champion) =>
          champion.name
            .toLowerCase()
            .trim()
            .replaceAll(' ', '')
            .includes(searchTerm.toLowerCase().trim().replaceAll(' ', '')) ||
          champion.korName
            .toLowerCase()
            .trim()
            .replaceAll(' ', '')
            .includes(searchTerm.toLowerCase().trim().replaceAll(' ', '')) // 한국어 이름에도 검색 적용
      );
      return filtered;
    }
  }, [searchTerm, champions]);

  useEffect(() => {
    setFilteredChampions(searchChampions);
  }, [searchChampions]);

  return (
    <PlayerSearchContainer containerHeight={containerHeight}>
      <SearchBar>
        <SearchButton>
          <FontAwesomeIcon icon={faSearch} />
        </SearchButton>
        <CustomInput
          placeholder={t('draft.searchchamp')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      <PlayerGrid>
        {filteredChampions.map((data, index) => (
          <ImageWithName
            key={index}
            name={data.korName}
            image={data.image}
            radius={40}
            onClick={() =>
              (cannotPickChampions?.find(
                (champion) => champion !== null && champion.index === data.index
              )
                ? true
                : false) ||
              (onChampionClick && !isPickCompleted && onChampionClick(data))
            }
            highlighted={data.index === 1}
            isBlack={
              cannotPickChampions?.find(
                (champion) => champion !== null && champion.index === data.index
              )
                ? true
                : false
            }
          />
        ))}
      </PlayerGrid>
    </PlayerSearchContainer>
  );
};

export default SearchChampion;
