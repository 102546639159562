import { useState, useEffect } from 'react';
import axios from 'axios';
import { Champ } from '../pages/inference/recommend/type';

const useChampions = () => {
  const [champions, setChampions] = useState<Champ[]>([]);

  useEffect(() => {
    const patchVersions = async () => {
      try {
        const response = await axios.get(
          'https://ddragon.leagueoflegends.com/api/versions.json'
        );
        const versions = response;
        return versions.data[0];
      } catch (error) {
        console.error('Error fetching patch versions: ', error);
        return '14.24.1';
      }
    };

    const fetchChampions = async () => {
      try {
        const version = await patchVersions();
        // 영어 데이터 가져오기
        const responseEn = await axios.get(
          `https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion.json`
        );
        const championDataEn = responseEn.data.data;

        // 한국어 데이터 가져오기
        const responseKo = await axios.get(
          `https://ddragon.leagueoflegends.com/cdn/${version}/data/ko_KR/champion.json`
        );
        const championDataKo = responseKo.data.data;

        // 영어 데이터와 한국어 데이터 매핑 및 한글 이름으로 정렬
        const championsList: Champ[] = Object.keys(championDataEn)
          .map((key) => {
            return {
              index: championDataEn[key].key,
              name: championDataEn[key].id,
              korName: championDataKo[key].name, // 한국어 이름
              image: `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championDataEn[key].id}.png`,
            };
          })
          .sort((a, b) => a.korName.localeCompare(b.korName, 'ko')); // 한글 이름으로 정렬

        setChampions(championsList);
      } catch (error) {
        console.error('Error fetching champion data: ', error);
      }
    };

    fetchChampions();
  }, []);

  return champions;
};

export const fetchChampionsData = async (): Promise<Champ[]> => {
  try {
    const response = await axios.get(
      'https://ddragon.leagueoflegends.com/api/versions.json'
    );
    const version = response.data[0];

    // 영어 데이터 가져오기
    const responseEn = await axios.get(
      `https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion.json`
    );
    const championDataEn = responseEn.data.data;

    // 한국어 데이터 가져오기
    const responseKo = await axios.get(
      `https://ddragon.leagueoflegends.com/cdn/${version}/data/ko_KR/champion.json`
    );
    const championDataKo = responseKo.data.data;

    // 영어 데이터와 한국어 데이터를 매핑하고, 한글 이름으로 정렬
    const championsList: Champ[] = Object.keys(championDataEn)
      .map((key) => ({
        index: championDataEn[key].key,
        name: championDataEn[key].id,
        korName: championDataKo[key].name, // 한국어 이름
        image: `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championDataEn[key].id}.png`,
      }))
      .sort((a, b) => a.korName.localeCompare(b.korName, 'ko')); // 한글 이름으로 정렬

    return championsList;
  } catch (error) {
    console.error('Error fetching champion data: ', error);
    return [];
  }
};

export default useChampions;
