import React from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // 배경 반투명 처리
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;
`;

const SimulateSetterContainer = styled.div`
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: column;

  background-color: #282828;
  gap: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;

  font-size: 12px;

  width: 340px;
  height: 320px;
  color: #fafafa;
`;

const SimulateChooseButton = styled.div<{ $isActivated?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 1.5%;
  width: 132px;
  height: 38px;
  box-sizing: border-box;

  background: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? 'rgba(126, 64, 255, 0.2)'
      : 'rgba(126, 64, 255, 1)'};
  box-shadow: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? 'none'
      : '0px 0px 4px 2px rgba(126,62,255, 0.5)'};
  border-radius: 6px;

  font-family: 'Pretendard';
  font-style: normal;
  font-size: 1em;
  text-align: center;
  cursor: pointer;

  color: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? '#727272'
      : '#fafafa'};
`;

const SimulateSetter = observer(
  ({ vm, onClose }: { vm: any; onClose: () => void }) => {
    const handleContainerClick = (event: React.MouseEvent) => {
      event.stopPropagation();
    };
    const { t } = useTranslation();

    return (
      <Overlay onClick={onClose}>
        <SimulateSetterContainer onClick={handleContainerClick}>
          <div>{t('draft.simpolicy')}</div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '30px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              >
                {t('draft.blue')}
              </div>
              <SimulateChooseButton
                $isActivated={vm.blueSimulatePolicy['optimized']}
                onClick={() => vm.setBlueSimulate('optimized')}
              >
                {t('draft.optimized')}
              </SimulateChooseButton>
              <SimulateChooseButton
                $isActivated={vm.blueSimulatePolicy['winrate']}
                onClick={() => vm.setBlueSimulate('winrate')}
              >
                {t('draft.wincentral')}
              </SimulateChooseButton>
              <SimulateChooseButton
                $isActivated={vm.blueSimulatePolicy['pickrate']}
                onClick={() => vm.setBlueSimulate('pickrate')}
              >
                {t('draft.pickcentral')}
              </SimulateChooseButton>
              <SimulateChooseButton
                $isActivated={vm.blueSimulatePolicy['custom']}
                onClick={() => vm.setBlueSimulate('custom')}
              >
                {t('draft.custom')}
              </SimulateChooseButton>
              <CustomSlider
                v={vm.blueSimulateCustomWinWeight * 100}
                setV={vm.setBlueSimulateCustomWinWeight}
                minv={0}
                maxv={100}
                activated={vm.blueSimulatePolicy['custom']}
              />
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: 300,
                  opacity: vm.blueSimulatePolicy['custom'] ? 0.8 : 0.2,
                }}
              >
                {t('draft.winrate')}{' '}
                {(vm.blueSimulateCustomWinWeight * 100).toFixed(0)}% /{' '}
                {t('draft.pickrate')}{' '}
                {((1 - vm.blueSimulateCustomWinWeight) * 100).toFixed(0)}%
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Pretendard',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              >
                {t('draft.red')}
              </div>
              <SimulateChooseButton
                $isActivated={vm.redSimulatePolicy['optimized']}
                onClick={() => vm.setRedSimulate('optimized')}
              >
                {t('draft.optimized')}
              </SimulateChooseButton>
              <SimulateChooseButton
                $isActivated={vm.redSimulatePolicy['winrate']}
                onClick={() => vm.setRedSimulate('winrate')}
              >
                {t('draft.wincentral')}
              </SimulateChooseButton>
              <SimulateChooseButton
                $isActivated={vm.redSimulatePolicy['pickrate']}
                onClick={() => vm.setRedSimulate('pickrate')}
              >
                {t('draft.pickcentral')}
              </SimulateChooseButton>
              <SimulateChooseButton
                $isActivated={vm.redSimulatePolicy['custom']}
                onClick={() => vm.setRedSimulate('custom')}
              >
                {t('draft.custom')}
              </SimulateChooseButton>
              <CustomSlider
                v={vm.redSimulateCustomWinWeight * 100}
                setV={vm.setRedSimulateCustomWinWeight}
                minv={0}
                maxv={100}
                activated={vm.redSimulatePolicy['custom']}
              />
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: 300,
                  opacity: vm.redSimulatePolicy['custom'] ? 0.8 : 0.2,
                }}
              >
                {t('draft.winrate')}{' '}
                {(vm.redSimulateCustomWinWeight * 100).toFixed(0)}% /{' '}
                {t('draft.pickrate')}{' '}
                {((1 - vm.redSimulateCustomWinWeight) * 100).toFixed(0)}%
              </div>
            </div>
          </div>
        </SimulateSetterContainer>
      </Overlay>
    );
  }
);

// Toggle Container
const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: fit-content;
`;

// Toggle Switch
const Switch = styled.div<{ $isActive: boolean; $isNotUsable?: boolean }>`
  width: 40px;
  height: 28px;
  box-sizing: border-box;

  background: transparent;
  border-radius: 19px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: ${({ $isNotUsable }) => ($isNotUsable ? 'default' : 'pointer')};
  border: ${(props) =>
    props.$isNotUsable
      ? '4px solid #fafafa30'
      : props.$isActive
      ? '4px solid #7e40ff'
      : '4px solid #fafafa'};
  position: relative;

  transition: border 0.3s ease;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Circle = styled.div<{ $isActive: boolean; $isNotUsable?: boolean }>`
  width: 12px;
  height: 12px;
  background: ${(props) => (props.$isNotUsable ? '#7e40ff30' : '#7e40ff')};
  border-radius: 50%;
  transition: transform 0.3s ease, background 0.3s ease;
  transform: ${({ $isActive }) =>
    $isActive ? 'translateX(14px)' : 'translateX(0)'};
`;

const ToggleText = styled.div<{ $isNotUsable?: boolean }>`
  font-size: 12px;
  font-weight: 100;
  color: ${(props) => (props.$isNotUsable ? '#fafafa30' : '#fafafa')};
  transition: color 0.3s ease;
`;

const PosToggleSwitch = observer(({ isactive, isnotusable, onClickF }: any) => {
  const handleToggle = () => {
    onClickF();
  };

  return (
    <Container>
      <ToggleContainer>
        <Switch
          $isActive={isactive}
          $isNotUsable={isnotusable}
          onClick={!isnotusable ? handleToggle : undefined}
        >
          <Circle $isActive={isactive} $isNotUsable={isnotusable} />
        </Switch>
      </ToggleContainer>
    </Container>
  );
});

const CustomSlider = ({
  v,
  setV,
  minv,
  maxv,
  activated = true,
}: {
  v: number;
  setV: (value: number) => void;
  minv: number;
  maxv: number;
  activated?: boolean;
}) => {
  const handleChange = (value: number | number[]) => {
    if (typeof value === 'number' && activated) {
      setV(value);
    }
  };

  return (
    <div
      style={{
        width: '80%',
        opacity: activated ? 1 : 0.4,
      }}
    >
      <Slider min={minv} max={maxv} value={v} onChange={handleChange} />
    </div>
  );
};

const KpSetterContainer = styled.div`
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: column;

  background-color: #f6f6f6;
  gap: 15px;
  align-items: center;
  justify-content: center;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;

  font-size: 12px;

  width: 160px;
  height: 236px;
  color: #121212;
`;

const ToggleTotalContainer = styled.div`
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: column;

  backdrop-filter: blur(8px);
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #c7c7c750;
  background-color: #f6f6f601;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;

  font-size: 12px;

  width: 120px;
  height: 100%;
  color: #fafafa;

  text-align: center;
`;

const KpSetter = observer(
  ({ vm, onClose }: { vm: any; onClose: () => void }) => {
    const { t } = useTranslation();
    const handleContainerClick = (event: React.MouseEvent) => {
      event.stopPropagation();
    };

    return (
      <Overlay onClick={onClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '80%',
            height: '100%',
            maxHeight: '236px',
            gap: '20px',
          }}
        >
          <KpSetterContainer onClick={handleContainerClick}>
            <div
              style={{
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {t('draft.winrate')} = {(vm.winrateWeightValue * 100).toFixed(0)}%
            </div>
            <div>
              {t('draft.winrate1')} {(vm.winrateWeightValue * 100).toFixed(0)}%{' '}
              {t('draft.winrate2')}
            </div>
            <CustomSlider
              v={vm.winrateWeightValue * 100}
              setV={vm.setWinrateWeightValue}
              minv={0}
              maxv={100}
            />

            <div
              style={{
                fontWeight: 600,
                fontSize: '16px',
              }}
            >
              {t('draft.pickrate')} ={' '}
              {((1 - vm.winrateWeightValue) * 100).toFixed(0)}%
            </div>
            <div>
              {t('draft.pickrate1')}{' '}
              {((1 - vm.winrateWeightValue) * 100).toFixed(0)}%{' '}
              {t('draft.pickrate2')}
            </div>
          </KpSetterContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-between',
              justifyContent: 'space-between',
              height: '100%',
              gap: '20px',
            }}
          >
            <ToggleTotalContainer onClick={handleContainerClick}>
              <ToggleText>{t('draft.resetWeight?')}</ToggleText>
              <PosToggleSwitch
                isactive={vm.recommendResetWeight}
                isnotusable={vm.loadingState.recommend}
                onClickF={vm.setRecommendResetWeight}
              />
            </ToggleTotalContainer>
            <ToggleTotalContainer onClick={handleContainerClick}>
              <ToggleText>{t('draft.setSide')}</ToggleText>
              {/* {vm.recommendSideIsMe ? (
                <ToggleText>{t('draft.nowside-me')}</ToggleText>
              ) : (
                <ToggleText>{t('draft.nowside-pick')}</ToggleText>
              )} */}
              <PosToggleSwitch
                isactive={vm.recommendSideIsChoosing}
                isnotusable={vm.loadingState.recommend}
                onClickF={vm.setRecommendSideIsChoosing}
              />
            </ToggleTotalContainer>
          </div>
        </div>
      </Overlay>
    );
  }
);

export { SimulateSetter, KpSetter };
