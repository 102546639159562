import React from 'react';
import styled from 'styled-components';
import SearchChampion from '../../../../../components/search/SearchChampion';
import { observer } from 'mobx-react';
import { SearchPlayer } from '../../../../../components/search/SearchPlayer';
import { usePlayer } from '../../../../../hooks/context/PlayerContext';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{
  $isActivated?: boolean;
}>`
  width: 20%;
  height: 100%;

  background-color: ${(props) =>
    props.$isActivated ? 'rgba(126, 64, 255, 0.25)' : 'rgba(126, 64, 255, 1)'};
  border-radius: 8px;

  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.$isActivated ? '#727272' : '#fafafa')};

  gap: 20px;
  cursor: ${(props) => (props.$isActivated ? 'default' : 'pointer')};
`;

const SelectedImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #fafafa;
`;

const Text = styled.div`
  text-align: center;
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 700;

  white-space: pre-wrap;
`;

const SimulateChooseChampion = observer(({ vm }: any) => {
  const { isLoading, filteredPlayers } = usePlayer();
  const { t } = useTranslation();

  if (vm.searchMode === false) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 210,
          width: '100%',
          flexDirection: 'row',
          gap: '2%',
        }}
      >
        <SearchChampion
          containerHeight="210px"
          onChampionClick={vm.simulateSetSelectedChamp}
          cannotPickChampions={Object.values(
            vm.simulationDraftboardState?.champTemplateBasic
          )}
          isPickCompleted={vm.isSimulatePickCompleted}
        />
        <Container
          $isActivated={
            vm.simulateSelectedChamp?.korName !== undefined ? false : true
          }
          onClick={() => {
            vm.simulateSelectedChamp?.korName && vm.nextTurn();
          }}
        >
          {vm.simulateSelectedChamp?.image && (
            <SelectedImage src={vm.simulateSelectedChamp?.image} />
          )}
          <Text>{`${
            vm.simulateSelectedChamp?.korName
              ? vm.simulateSelectedChamp?.korName + '\n' + t('draft.choosewid')
              : t('draft.choosechamp')
          }`}</Text>
        </Container>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 210,
          width: '100%',
          flexDirection: 'row',
          gap: '2%',
        }}
      >
        <SearchPlayer
          onPlayerClick={vm.simulateSetSelectedPlayer}
          containerHeight={`210px`}
          filteredPlayers={filteredPlayers}
          isLoading={isLoading}
        />
      </div>
    );
  }
});

export default SimulateChooseChampion;
