import React from 'react';
import DashboardView from './dashboardview';
import RecommendStage1 from './component/stage1/RecommendStage1';
import { observer } from 'mobx-react';
import { MenuArea } from '../../../components/mainMenu/MenuArea';
import { PlayerProvider } from '../../../hooks/context/PlayerContext';
import BanpickRecordItem from './component/stage3';
import BanpickDetail from './component/stage3/BanPickHistory';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProvidedRecommendStage1 = observer(({ vm }: any) => {
  return (
    <PlayerProvider>
      <RecommendStage1 vm={vm} />
    </PlayerProvider>
  );
});

const RecommendArea = observer(({ vm }: any) => {
  return (
    <MenuArea title="Dashboard" selectedItem="Dashboard">
      {vm.overallStep === 0 && <ProvidedRecommendStage1 vm={vm} />}
      {vm.overallStep === 1 && <DashboardView vm={vm} />}
      {vm.overallStep === 2 && (
        <div style={{ width: '100%' }}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={() => vm.setOverallStep(1)}
            style={{
              cursor: 'pointer',
              fontSize: '1.5rem',
              padding: '30px 30px 20px 30px',
            }}
          />
          <BanpickRecordItem record={vm.historyData} />
        </div>
      )}
    </MenuArea>
  );
});

export default RecommendArea;
