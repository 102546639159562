import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import WidgetBox from '../component/stage2/WidgetContainer';
import RecommendCard from '../component/stage2/RecommendCard';
import { Champ, RecommendedChamp } from '../type';
import { observer } from 'mobx-react';
import { KpSetter } from '../component/stage2/ParameterSetter';
import { toJS } from 'mobx';
import { LoadingSpinner } from '../component/stage2/loadingSpinner';
import { useTranslation } from 'react-i18next';

const VerticalScrollBox = styled.div`
  display: flex;
  width: 100%;
  height: 180px;
  margin: 10px 0;

  padding: 0 5px;

  flex-direction: column;
  gap: 5px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7e40ff50;
    border-radius: 4px;
    height: 30px;
  }
`;

const ChooseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ChooseButton = styled.div<{ $isActivated: boolean }>`
  width: 160px;
  height: 38px;

  background: ${(props) =>
    props.$isActivated ? 'rgba(126, 64, 255, 0.25)' : 'rgba(126, 64, 255, 1)'};
  border-radius: 12px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => (props.$isActivated ? '#727272' : '#fafafa')};

  cursor: ${(props) => (props.$isActivated ? 'default' : 'pointer')};
`;

const MetaContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MetaText = styled.div`
  font-size: 12px;
  color: #fafafa;
`;

const ScrollableContainer = ({ children }: { children: React.ReactNode }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = scrollRef.current;

    // wheel 이벤트 핸들러
    const handleWheel = (e: WheelEvent) => {
      if (!container) return;

      // 현재 스크롤 상태와 세로 스크롤 가능한 최대 크기 계산
      const { scrollTop, scrollHeight, clientHeight } = container;

      // 새로 적용할 스크롤 위치 계산
      const newScrollTop = scrollTop + e.deltaY;

      // 실제로 스크롤이 변경되는지 확인(클램핑)
      const clampedScrollTop = Math.max(
        0,
        Math.min(newScrollTop, scrollHeight - clientHeight)
      );

      // 세로 스크롤이 필요한 경우 기본 동작 차단하고 수동으로 처리
      if (clampedScrollTop !== scrollTop) {
        container.scrollTop = clampedScrollTop;
        e.preventDefault(); // 기본 동작 차단
      }
    };

    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  // 세로 스크롤만 가능하도록 overflow-y: auto 설정
  return <VerticalScrollBox ref={scrollRef}>{children}</VerticalScrollBox>;
};

const RecommendChampWidget = observer(({ vm }: any) => {
  const { t } = useTranslation();
  const [showParameterSetter, setShowParameterSetter] = useState(false);
  const [recommendChamps, setRecommendChamps] = useState<RecommendedChamp[]>(
    []
  );

  // 파라미터 아이콘 클릭 시 호출될 함수
  const handleParameterIconClick = () => {
    setShowParameterSetter(!showParameterSetter);
  };

  useEffect(() => {
    if (vm.recommendResultOut === null) {
      return () => {
        setRecommendChamps(vm.recommendChampsState.recommendChamps);
      };
    }
    vm.updateRecommendation({
      recommendResult: vm.recommendResultOut,
      turn: vm.parentState.turn - 1,
    });

    return () => {
      setRecommendChamps(vm.recommendChampsState.recommendChamps);
    };
    // eslint-disable-next-line
  }, [vm.winrateWeightValue, vm.recommendResultOut]);

  if (vm.loadingState.recommend === true) {
    return (
      <WidgetBox vm={vm} type="recommendChamps">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingSpinner width={60} />
        </div>
      </WidgetBox>
    );
  }

  return (
    <WidgetBox
      vm={vm}
      type="recommendChamps"
      canModify={true}
      onParameterClick={handleParameterIconClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: '0px 20px',
          boxSizing: 'border-box',
        }}
      >
        {/* ParameterSetter를 조건부로 렌더링 */}
        {showParameterSetter && (
          <KpSetter vm={vm} onClose={() => setShowParameterSetter(false)} />
        )}
        {/* <MetaContainer>
          <MetaText>
            {t('draft.recommend')} {t('draft.turn')} {vm.parentState.turn}
          </MetaText>
        </MetaContainer> */}
        <ScrollableContainer>
          {vm.parentState.turn === 20 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                fontSize: '20px',
                color: '#727272',
              }}
            >
              {t('draft.recocomp')}
            </div>
          ) : (
            recommendChamps.map(
              (recommendChamp: RecommendedChamp, index: number) => {
                const isNon = (
                  Object.values(
                    vm.draftboardState.champTemplateBasic
                  ) as Champ[]
                )
                  .concat(vm.globalBanState.globalBan as Champ[])
                  .some(
                    (champ: Champ) => champ?.index === recommendChamp?.index
                  );

                return (
                  <RecommendCard
                    vm={vm}
                    recommendChamp={recommendChamp}
                    isHighlighted={
                      vm.parentState?.selectedChamp?.index ===
                      recommendChamp.index
                    }
                    key={index}
                    onClickF={vm.setSelectedChamp}
                    isNonClickable={isNon}
                  />
                );
              }
            )
          )}
        </ScrollableContainer>
        <ChooseContainer>
          <ChooseButton
            $isActivated={
              vm.parentState?.selectedChamp?.korName !== undefined
                ? false
                : true
            }
            onClick={() =>
              vm.parentState?.selectedChamp?.korName && vm.nextTurn()
            }
          >
            {vm.parentState?.selectedChamp?.korName || ''}{' '}
            {t('draft.choosewid')}
          </ChooseButton>
        </ChooseContainer>
      </div>
    </WidgetBox>
  );
});

export default RecommendChampWidget;
