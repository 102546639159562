import {
  EmailSubmitRequest,
  EmailSubmitResponse,
  RegisterRequest,
  RegisterResponse,
  LoginRequest,
  LoginResponse,
  RefreshRequest,
  RefreshResponse,
  LogoutResponse,
  ChangeUserInfoRequest,
  ChangeUserInfoResponse,
  ForgotPasswordResponse,
  SendResetPasswordFormResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UploadUserImageResponse,
  ForgotPasswordRequest,
  LogoutRequest,
} from './types/authApiTypes';
import apiClient from './apiClient';

export const emailSubmit = async (
  userData: EmailSubmitRequest
): Promise<EmailSubmitResponse> => {
  try {
    const response = await apiClient.post<EmailSubmitResponse>(
      '/auth/findEmail',
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 회원가입
export const register = async (
  userData: RegisterRequest
): Promise<RegisterResponse> => {
  try {
    const response = await apiClient.post<RegisterResponse>(
      '/auth/register',
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 로그인
export const login = async (userData: LoginRequest): Promise<LoginResponse> => {
  try {
    const response = await apiClient.post<LoginResponse>(
      '/auth/login',
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 토큰 갱신
export const refresh = async (
  refreshToken: RefreshRequest
): Promise<RefreshResponse> => {
  try {
    const response = await apiClient.post<RefreshResponse>('/auth/refresh', {
      refreshToken,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 로그아웃
export const logout = async (
  userData: LogoutRequest
): Promise<LogoutResponse> => {
  try {
    const response = await apiClient.post<LogoutResponse>('/auth/logout', {
      email: userData.accessToken,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 회원정보 변경
export const changeUserInfo = async (
  userData: ChangeUserInfoRequest
): Promise<ChangeUserInfoResponse> => {
  try {
    const response = await apiClient.post<ChangeUserInfoResponse>(
      '/auth/changeUserInfo',
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 비밀번호 재설정 이메일 전송
export const forgotPassword = async ({
  email,
}: ForgotPasswordRequest): Promise<ForgotPasswordResponse> => {
  try {
    const response = await apiClient.post<ForgotPasswordResponse>(
      '/auth/forgotPassword',
      { email }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 비밀번호 재설정 폼 전송
export const sendResetPasswordForm = async (
  paramId: string
): Promise<SendResetPasswordFormResponse> => {
  try {
    const response = await apiClient.get<SendResetPasswordFormResponse>(
      `/resetPasswordForm/${paramId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 비밀번호 재설정
export const resetPassword = async (
  userData: ResetPasswordRequest
): Promise<ResetPasswordResponse> => {
  try {
    const response = await apiClient.post<ResetPasswordResponse>(
      '/auth/resetPassword',
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 사용자 이미지 저장
export const uploadUserImage = async (
  file: File
): Promise<UploadUserImageResponse> => {
  const formData = new FormData();
  formData.append('img', file);

  try {
    const response = await apiClient.post<UploadUserImageResponse>(
      '/auth/userImgUp',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
