import { useAuth } from '../../hooks/context/AuthContext';
import AuthStep1 from './component/stage/AuthStep1';
import AuthStep2 from './component/stage/AuthStep2';
import SignUp from './component/stage/SignUp';
import SignUpComplete from './component/stage/SignUpComplete';
import ResetPassword1 from './component/stage/ResetPassword1';
import { ResetPassword2 } from './component/stage/ResetPassword2';
import styled from 'styled-components';
import LangToggle from '../../components/common/langtoggle';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-style: normal;
  height: 100vh;
  background: url('/authbackground.png') no-repeat center center fixed;
  background-size: cover;
`;

const PlaceHolder = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const AuthArea = () => {
  const { step } = useAuth();

  return (
    <Container>
      <PlaceHolder>
        {' '}
        <LangToggle />{' '}
      </PlaceHolder>
      {step === 1 && <AuthStep1 />}
      {step === 2 && <AuthStep2 />}
      {step === 3 && <SignUp />}
      {step === 4 && <SignUpComplete />}
      {step === 5 && <ResetPassword1 />}
      {step === 6 && <ResetPassword2 />}
    </Container>
  );
};

export default AuthArea;
